define("ember-composable-helpers/helpers/flatten", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flatten = flatten;
  _exports.default = void 0;

  function flatten(array) {
    if (!Ember.isArray(array)) {
      return array;
    }

    return array.reduce((flattened, el) => {
      return flattened.concat(flatten(el));
    }, []);
  }

  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [array] = _ref;
      Ember.set(this, 'array', array);
      return flatten(array);
    },

    arrayContentDidChange: Ember.observer('array.[]', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});