define("ember-power-select/helpers/ember-power-select-true-string-if-present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberPowerSelectTrueStringIfPresent = emberPowerSelectTrueStringIfPresent;
  _exports.default = void 0;

  function emberPowerSelectTrueStringIfPresent(_ref
  /* , hash*/
  ) {
    let [bool] = _ref;
    return bool ? 'true' : false;
  }

  var _default = Ember.Helper.helper(emberPowerSelectTrueStringIfPresent);

  _exports.default = _default;
});