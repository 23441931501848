define("ember-content-placeholders/templates/components/content-placeholders-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MeAmt0I+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"img\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__img\"]]],[10,\"data-test-ember-content-placeholders-heading-img\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__content\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__title\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__subtitle\"]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-content-placeholders/templates/components/content-placeholders-heading.hbs"
    }
  });

  _exports.default = _default;
});