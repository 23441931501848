define("ember-parachute/-private/query-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    canInvoke
  } = Ember;
  /**
   * Normalized query param object.
   *
   * @export
   * @class QueryParam
   */

  class QueryParam {
    constructor(key) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      (false && !(Ember.isPresent(key)) && Ember.assert("[ember-parachute] You must specify a key to the QueryParam Class", Ember.isPresent(key)));
      this.key = key;
      this.as = options.as || key;
      this.scope = options.scope;
      this.defaultValue = options.defaultValue;
      this.refresh = Boolean(options.refresh);
      this.replace = Boolean(options.replace);
      this.serialize = options.serialize;
      this.deserialize = options.deserialize;
    }
    /**
     * Current query param value.
     *
     * @param {Ember.Controller} controller
     * @returns {any}
     *
     * @memberof QueryParam
     */


    value(controller) {
      return Ember.get(controller, this.key);
    }
    /**
     * Current query param serialized value.
     *
     * @param {Ember.Controller} controller
     * @returns {any}
     *
     * @memberof QueryParam
     */


    serializedValue(controller) {
      const value = this.value(controller);
      return canInvoke(this, 'serialize') ? this.serialize(value, controller) : value;
    }
    /**
     * String representation of the query param object.
     *
     * @returns {string}
     *
     * @memberof QueryParam
     */


    toString() {
      return "QueryParam<".concat(this.key, ">");
    }

  }

  _exports.default = QueryParam;
});