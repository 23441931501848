define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/IMUIrfH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\"],[[28,\"component\",[[24,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"disabled\",\"readonly\",\"onChange\",\"_onChange\"],[[24,[\"model\"]],[24,[\"formLayout\"]],[24,[\"horizontalLabelGridClass\"]],[24,[\"showAllValidations\"]],[23,0,[\"disabled\"]],[23,0,[\"readonly\"]],[28,\"action\",[[23,0,[]],\"change\"],null],[28,\"action\",[[23,0,[]],\"resetSubmissionState\"],null]]]],[28,\"component\",[[24,[\"groupComponent\"]]],[[\"formLayout\"],[[24,[\"formLayout\"]]]]],[24,[\"isSubmitting\"]],[24,[\"isSubmitted\"]],[24,[\"isRejected\"]],[28,\"action\",[[23,0,[]],\"resetSubmissionState\"],null],[28,\"action\",[[23,0,[]],\"submit\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });

  _exports.default = _default;
});