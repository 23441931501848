define("ember-macro-helpers/is-computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(key) {
    return key instanceof Ember.ComputedProperty;
  }
});