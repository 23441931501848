define('@html-next/vertical-collection/-private', ['exports', 'ember-raf-scheduler'], function (exports, emberRafScheduler) { 'use strict';

  function identity(item) {
    let key;
    const type = typeof item;

    if (type === 'string' || type === 'number') {
      key = item;
    } else {
      key = Ember.guidFor(item);
    }

    return key;
  }

  function keyForItem(item, keyPath, index) {
    let key;

    switch (keyPath) {
      case '@index':
        key = index;
        break;

      case '@identity':
        key = identity(item);
        break;

      default:
        key = Ember.get(item, keyPath);
    }

    if (typeof key === 'number') {
      key = String(key);
    }

    return key;
  }

  const VENDOR_MATCH_FNS = ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'];
  let ELEMENT_MATCH_FN;

  function setElementMatchFn(el) {
    VENDOR_MATCH_FNS.forEach(fn => {
      if (ELEMENT_MATCH_FN === undefined && typeof el[fn] === 'function') {
        ELEMENT_MATCH_FN = fn;
      }
    });
  }

  function closest(el, selector) {
    if (ELEMENT_MATCH_FN === undefined) {
      setElementMatchFn(el);
    }

    while (el) {
      // TODO add explicit test
      if (el[ELEMENT_MATCH_FN](selector)) {
        return el;
      }

      el = el.parentElement;
    }

    return null;
  }

  var document$1 = window ? window.document : undefined;

  let VC_IDENTITY = 0;
  class VirtualComponent {
    constructor(content, index) {
      if (content === void 0) {
        content = null;
      }

      if (index === void 0) {
        index = null;
      }

      this.id = "VC-" + VC_IDENTITY++;
      this.content = content;
      this.index = index; // We check to see if the document exists in Fastboot. Since RAF won't run in
      // Fastboot, we'll never have to use these text nodes for measurements, so they
      // can be empty

      this.upperBound = document$1 !== undefined ? document$1.createTextNode('') : null;
      this.lowerBound = document$1 !== undefined ? document$1.createTextNode('') : null;
      this.rendered = false; // In older versions of Ember/IE, binding anything on an object in the template
      // adds observers which creates __ember_meta__

      this.__ember_meta__ = null; // eslint-disable-line camelcase
    }

    get realUpperBound() {
      return  this.upperBound ;
    }

    get realLowerBound() {
      return  this.lowerBound ;
    }

    getBoundingClientRect() {
      let {
        upperBound,
        lowerBound
      } = this;
      let top = Infinity;
      let bottom = -Infinity;

      while (upperBound !== lowerBound) {
        upperBound = upperBound.nextSibling;

        if (upperBound instanceof Element) {
          top = Math.min(top, upperBound.getBoundingClientRect().top);
          bottom = Math.max(bottom, upperBound.getBoundingClientRect().bottom);
        }
      }
      const height = bottom - top;
      return {
        top,
        bottom,
        height
      };
    }

    recycle(newContent, newIndex) {

      if (this.index !== newIndex) {
        Ember.set(this, 'index', newIndex);
      }

      if (this.content !== newContent) {
        Ember.set(this, 'content', newContent);
      }
    }

    destroy() {
      Ember.set(this, 'upperBound', null);
      Ember.set(this, 'lowerBound', null);
      Ember.set(this, 'content', null);
      Ember.set(this, 'index', null);
    }

  }

  let OC_IDENTITY = 0;
  class OccludedContent {
    constructor(tagName) {
      this.id = "OC-" + OC_IDENTITY++;
      this.isOccludedContent = true; // We check to see if the document exists in Fastboot. Since RAF won't run in
      // Fastboot, we'll never have to use these text nodes for measurements, so they
      // can be empty

      if (document$1 !== undefined) {
        this.element = document$1.createElement(tagName);
        this.element.className += 'occluded-content';
        this.upperBound = document$1.createTextNode('');
        this.lowerBound = document$1.createTextNode('');
      } else {
        this.element = null;
      }

      this.isOccludedContent = true;
      this.rendered = false; // In older versions of Ember/IE, binding anything on an object in the template
      // adds observers which creates __ember_meta__

      this.__ember_meta__ = null; // eslint-disable-line camelcase
    }

    getBoundingClientRect() {
      if (this.element !== null) {
        return this.element.getBoundingClientRect();
      }
    }

    addEventListener(event, listener) {
      if (this.element !== null) {
        this.element.addEventListener(event, listener);
      }
    }

    removeEventListener(event, listener) {
      if (this.element !== null) {
        this.element.removeEventListener(event, listener);
      }
    }

    get realUpperBound() {
      return  this.upperBound ;
    }

    get realLowerBound() {
      return  this.lowerBound ;
    }

    get parentNode() {
      return this.element !== null ? this.element.parentNode : null;
    }

    get style() {
      return this.element !== null ? this.element.style : {};
    }

    set innerHTML(value) {
      if (this.element !== null) {
        this.element.innerHTML = value;
      }
    }

    destroy() {
      Ember.set(this, 'element', null);
    }

  }

  function insertRangeBefore(parent, element, firstNode, lastNode) {
    let nextNode;

    while (firstNode) {
      nextNode = firstNode.nextSibling;
      parent.insertBefore(firstNode, element);

      if (firstNode === lastNode) {
        break;
      }

      firstNode = nextNode;
    }
  }

  function objectAt(arr, index) {
    return arr.objectAt ? arr.objectAt(index) : arr[index];
  }

  function roundTo(number, decimal) {
    if (decimal === void 0) {
      decimal = 2;
    }

    const exp = Math.pow(10, decimal);
    return Math.round(number * exp) / exp;
  }

  function isPrepend(lenDiff, newItems, key, oldFirstKey, oldLastKey) {
    const newItemsLength = Ember.get(newItems, 'length');

    if (lenDiff <= 0 || lenDiff >= newItemsLength || newItemsLength === 0) {
      return false;
    }

    const newFirstKey = keyForItem(objectAt(newItems, lenDiff), key, lenDiff);
    const newLastKey = keyForItem(objectAt(newItems, newItemsLength - 1), key, newItemsLength - 1);
    return oldFirstKey === newFirstKey && oldLastKey === newLastKey;
  }
  function isAppend(lenDiff, newItems, key, oldFirstKey, oldLastKey) {
    const newItemsLength = Ember.get(newItems, 'length');

    if (lenDiff <= 0 || lenDiff >= newItemsLength || newItemsLength === 0) {
      return false;
    }

    const newFirstKey = keyForItem(objectAt(newItems, 0), key, 0);
    const newLastKey = keyForItem(objectAt(newItems, newItemsLength - lenDiff - 1), key, newItemsLength - lenDiff - 1);
    return oldFirstKey === newFirstKey && oldLastKey === newLastKey;
  }

  let supportsPassive = false;

  try {
    let opts = Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
        return supportsPassive;
      }

    });
    window.addEventListener('test', null, opts);
  } catch (e) {// do nothing
  }

  var SUPPORTS_PASSIVE = supportsPassive;

  const DEFAULT_ARRAY_SIZE = 10;
  const UNDEFINED_VALUE = Object.create(null);
  class ScrollHandler {
    constructor() {
      this.elements = new Array(DEFAULT_ARRAY_SIZE);
      this.maxLength = DEFAULT_ARRAY_SIZE;
      this.length = 0;
      this.handlers = new Array(DEFAULT_ARRAY_SIZE);
      this.isPolling = false;
      this.isUsingPassive = SUPPORTS_PASSIVE;
    }

    addScrollHandler(element, handler) {
      let index = this.elements.indexOf(element);
      let handlers, cache;

      if (index === -1) {
        index = this.length++;

        if (index === this.maxLength) {
          this.maxLength *= 2;
          this.elements.length = this.maxLength;
          this.handlers.length = this.maxLength;
        }

        handlers = [handler];
        this.elements[index] = element;
        cache = this.handlers[index] = {
          top: element.scrollTop,
          left: element.scrollLeft,
          handlers
        }; // TODO add explicit test

        if (SUPPORTS_PASSIVE) {
          cache.passiveHandler = function () {
            ScrollHandler.triggerElementHandlers(element, cache);
          };
        } else {
          cache.passiveHandler = UNDEFINED_VALUE;
        }
      } else {
        cache = this.handlers[index];
        handlers = cache.handlers;
        handlers.push(handler);
      } // TODO add explicit test


      if (this.isUsingPassive && handlers.length === 1) {
        element.addEventListener('scroll', cache.passiveHandler, {
          capture: true,
          passive: true
        }); // TODO add explicit test
      } else if (!this.isPolling) {
        this.poll();
      }
    }

    removeScrollHandler(element, handler) {
      let index = this.elements.indexOf(element);
      let elementCache = this.handlers[index]; // TODO add explicit test

      if (elementCache && elementCache.handlers) {
        let index = elementCache.handlers.indexOf(handler);

        if (index === -1) {
          throw new Error('Attempted to remove an unknown handler');
        }

        elementCache.handlers.splice(index, 1); // cleanup element entirely if needed
        // TODO add explicit test

        if (!elementCache.handlers.length) {
          index = this.elements.indexOf(element);
          this.handlers.splice(index, 1);
          this.elements.splice(index, 1);
          this.length--;
          this.maxLength--;

          if (this.length === 0) {
            this.isPolling = false;
          } // TODO add explicit test


          if (this.isUsingPassive) {
            element.removeEventListener('scroll', elementCache.passiveHandler, {
              capture: true,
              passive: true
            });
          }
        }
      } else {
        throw new Error('Attempted to remove a handler from an unknown element or an element with no handlers');
      }
    }

    static triggerElementHandlers(element, meta) {
      let cachedTop = element.scrollTop;
      let cachedLeft = element.scrollLeft;
      let topChanged = cachedTop !== meta.top;
      let leftChanged = cachedLeft !== meta.left;
      meta.top = cachedTop;
      meta.left = cachedLeft;
      let event = {
        top: cachedTop,
        left: cachedLeft
      }; // TODO add explicit test

      if (topChanged || leftChanged) {
        Ember.run.begin();

        for (let j = 0; j < meta.handlers.length; j++) {
          meta.handlers[j](event);
        }

        Ember.run.end();
      }
    }

    poll() {
      this.isPolling = true;
      emberRafScheduler.scheduler.schedule('sync', () => {
        // TODO add explicit test
        if (!this.isPolling) {
          return;
        }

        for (let i = 0; i < this.length; i++) {
          let element = this.elements[i];
          let info = this.handlers[i];
          ScrollHandler.triggerElementHandlers(element, info);
        }

        this.isPolling = this.length > 0; // TODO add explicit test

        if (this.isPolling) {
          this.poll();
        }
      });
    }

  }
  const instance = new ScrollHandler();
  function addScrollHandler(element, handler) {
    instance.addScrollHandler(element, handler);
  }
  function removeScrollHandler(element, handler) {
    instance.removeScrollHandler(element, handler);
  }

  /*
   * There are significant differences between browsers
   * in how they implement "scroll" on document.body
   *
   * The only cross-browser listener for scroll on body
   * is to listen on window with capture.
   *
   * They also implement different standards for how to
   * access the scroll position.
   *
   * This singleton class provides a cross-browser way
   * to access and set the scrollTop and scrollLeft properties.
   *
   */
  function ViewportContainer() {
    // A bug occurs in Chrome when we reload the browser at a lower
    // scrollTop, window.scrollY becomes stuck on a single value.
    Object.defineProperty(this, 'scrollTop', {
      get() {
        return document.body.scrollTop || document.documentElement.scrollTop;
      },

      set(v) {
        return document.body.scrollTop = document.documentElement.scrollTop = v;
      }

    });
    Object.defineProperty(this, 'scrollLeft', {
      get() {
        return window.scrollX || window.pageXOffset || document.body.scrollLeft || document.documentElement.scrollLeft;
      },

      set(v) {
        return window.scrollX = window.pageXOffset = document.body.scrollLeft = document.documentElement.scrollLeft = v;
      }

    });
    Object.defineProperty(this, 'offsetHeight', {
      get() {
        return window.innerHeight;
      }

    });
  }

  ViewportContainer.prototype.addEventListener = function addEventListener(event, handler, options) {
    return window.addEventListener(event, handler, options);
  };

  ViewportContainer.prototype.removeEventListener = function addEventListener(event, handler, options) {
    return window.removeEventListener(event, handler, options);
  };

  ViewportContainer.prototype.getBoundingClientRect = function getBoundingClientRect() {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
      top: 0,
      left: 0,
      right: window.innerWidth,
      bottom: window.innerHeight
    };
  };

  var ViewportContainer$1 = new ViewportContainer();

  function estimateElementHeight(element, fallbackHeight) {

    if (fallbackHeight.indexOf('%') !== -1) {
      return getPercentageHeight(element, fallbackHeight);
    }

    if (fallbackHeight.indexOf('em') !== -1) {
      return getEmHeight(element, fallbackHeight);
    }

    return parseInt(fallbackHeight, 10);
  }

  function getPercentageHeight(element, fallbackHeight) {
    // We use offsetHeight here to get the element's true height, rather than the
    // bounding rect which may be scaled with transforms
    let parentHeight = element.offsetHeight;
    let percent = parseFloat(fallbackHeight);
    return percent * parentHeight / 100.0;
  }

  function getEmHeight(element, fallbackHeight) {
    const fontSizeElement = fallbackHeight.indexOf('rem') !== -1 ? document.documentElement : element;
    const fontSize = window.getComputedStyle(fontSizeElement).getPropertyValue('font-size');
    return parseFloat(fallbackHeight) * parseFloat(fontSize);
  }

  function getScaledClientRect(element, scale) {
    const rect = element.getBoundingClientRect();

    if (scale === 1) {
      return rect;
    }

    const scaled = {};

    for (let key in rect) {
      scaled[key] = rect[key] * scale;
    }

    return scaled;
  }

  class Radar {
    constructor(parentToken, _ref) {
      let {
        bufferSize,
        containerSelector,
        estimateHeight,
        initialRenderCount,
        items,
        key,
        renderAll,
        renderFromLast,
        shouldRecycle,
        startingIndex,
        occlusionTagName
      } = _ref;
      this.token = new emberRafScheduler.Token(parentToken); // Public API

      this.bufferSize = bufferSize;
      this.containerSelector = containerSelector;
      this.estimateHeight = estimateHeight;
      this.initialRenderCount = initialRenderCount;
      this.items = items;
      this.key = key;
      this.renderAll = renderAll;
      this.renderFromLast = renderFromLast;
      this.shouldRecycle = shouldRecycle;
      this.startingIndex = startingIndex; // defaults to a no-op intentionally, actions will only be sent if they
      // are passed into the component

      this.sendAction = () => {}; // Calculated constants


      this._itemContainer = null;
      this._scrollContainer = null;
      this._prependOffset = 0;
      this._calculatedEstimateHeight = 0;
      this._collectionOffset = 0;
      this._calculatedScrollContainerHeight = 0;
      this._transformScale = 1; // Event handler

      this._scrollHandler = (_ref2) => {
        let {
          top
        } = _ref2;

        // debounce scheduling updates by checking to make sure we've moved a minimum amount
        if (this._didEarthquake(Math.abs(this._scrollTop - top))) {
          this.scheduleUpdate();
        }
      };

      this._resizeHandler = this.scheduleUpdate.bind(this); // Run state

      this._nextUpdate = null;
      this._nextLayout = null;
      this._started = false;
      this._didReset = true;
      this._didUpdateItems = false; // Cache state

      this._scrollTop = 0; // Setting these values to infinity starts us in a guaranteed good state for the radar,
      // so it knows that it needs to run certain measurements, etc.

      this._prevFirstItemIndex = Infinity;
      this._prevLastItemIndex = -Infinity;
      this._prevFirstVisibleIndex = 0;
      this._prevLastVisibleIndex = 0;
      this._firstReached = false;
      this._lastReached = false;
      this._prevTotalItems = 0;
      this._prevFirstKey = 0;
      this._prevLastKey = 0;
      this._componentPool = [];
      this._prependComponentPool = []; // Boundaries

      this._occludedContentBefore = new OccludedContent(occlusionTagName);
      this._occludedContentAfter = new OccludedContent(occlusionTagName);
      this._pageUpHandler = this.pageUp.bind(this);

      this._occludedContentBefore.addEventListener('click', this._pageUpHandler);

      this._pageDownHandler = this.pageDown.bind(this);

      this._occludedContentAfter.addEventListener('click', this._pageDownHandler); // Element to hold pooled component DOM when not in use


      if (document$1) {
        this._domPool = document$1.createDocumentFragment();
      } // Initialize virtual components


      this.virtualComponents = Ember.A([this._occludedContentBefore, this._occludedContentAfter]);
      this.orderedComponents = [];

      this._updateVirtualComponents(); // In older versions of Ember/IE, binding anything on an object in the template
      // adds observers which creates __ember_meta__


      this.__ember_meta__ = null; // eslint-disable-line camelcase
    }

    destroy() {
      this.token.cancel();

      for (let i = 0; i < this.orderedComponents.length; i++) {
        this.orderedComponents[i].destroy();
      } // Boundaries


      this._occludedContentBefore.removeEventListener('click', this._pageUpHandler);

      this._occludedContentAfter.removeEventListener('click', this._pageDownHandler);

      this._occludedContentBefore.destroy();

      this._occludedContentAfter.destroy();

      this.orderedComponents = null;
      Ember.set(this, 'virtualComponents', null);

      if (this._started) {
        removeScrollHandler(this._scrollContainer, this._scrollHandler);
        ViewportContainer$1.removeEventListener('resize', this._resizeHandler);
      }
    }

    schedule(queueName, job) {
      return emberRafScheduler.scheduler.schedule(queueName, job, this.token);
    }
    /**
     * Start the Radar. Does initial measurements, adds event handlers,
     * sets up initial scroll state, and
     */


    start() {
      const {
        startingIndex,
        containerSelector,
        _occludedContentBefore
      } = this; // Use the occluded content element, which has been inserted into the DOM,
      // to find the item container and the scroll container

      this._itemContainer = _occludedContentBefore.element.parentNode;
      this._scrollContainer = containerSelector === 'body' ? ViewportContainer$1 : closest(this._itemContainer, containerSelector);

      this._updateConstants(); // Setup initial scroll state


      if (startingIndex !== 0) {
        const {
          renderFromLast,
          _calculatedEstimateHeight,
          _collectionOffset,
          _calculatedScrollContainerHeight
        } = this;
        let startingScrollTop = startingIndex * _calculatedEstimateHeight;

        if (renderFromLast) {
          startingScrollTop -= _calculatedScrollContainerHeight - _calculatedEstimateHeight;
        } // initialize the scrollTop value, which will be applied to the
        // scrollContainer after the collection has been initialized


        this._scrollTop = startingScrollTop + _collectionOffset;
        this._prevFirstVisibleIndex = startingIndex;
      } else {
        this._scrollTop = this._scrollContainer.scrollTop;
      }

      this._started = true;
      this.update(); // Setup event handlers

      addScrollHandler(this._scrollContainer, this._scrollHandler);
      ViewportContainer$1.addEventListener('resize', this._resizeHandler);
    }
    /*
     * Schedules an update for the next RAF
     *
     * This will first run _updateVirtualComponents in the sync phase, which figures out what
     * components need to be rerendered and updates the appropriate VCs and moves their associated
     * DOM. At the end of the `sync` phase the runloop is flushed and Glimmer renders the changes.
     *
     * By the `affect` phase the Radar should have had time to measure, meaning it has all of the
     * current info and we can send actions for any changes.
     *
     * @private
     */


    scheduleUpdate(didUpdateItems) {
      if (didUpdateItems === true) {
        // Set the update items flag first, in case scheduleUpdate has already been called
        // but the RAF hasn't yet run
        this._didUpdateItems = true;
      }

      if (this._nextUpdate !== null || this._started === false) {
        return;
      }

      this._nextUpdate = this.schedule('sync', () => {
        this._nextUpdate = null;
        this._scrollTop = this._scrollContainer.scrollTop;
        this.update();
      });
    }

    update() {
      if (this._didUpdateItems === true) {
        this._determineUpdateType();

        this._didUpdateItems = false;
      }

      this._updateConstants();

      this._updateIndexes();

      this._updateVirtualComponents();

      this.schedule('measure', this.afterUpdate.bind(this));
    }

    afterUpdate() {
      const {
        _prevTotalItems: totalItems
      } = this;

      const scrollDiff = this._calculateScrollDiff();

      if (scrollDiff !== 0) {
        this._scrollContainer.scrollTop += scrollDiff;
      } // Re-sync scrollTop, since Chrome may have intervened


      this._scrollTop = this._scrollContainer.scrollTop; // Unset prepend offset, we're done with any prepend changes at this point

      this._prependOffset = 0;

      if (totalItems !== 0) {
        this._sendActions();
      } // Cache previous values


      this._prevFirstItemIndex = this.firstItemIndex;
      this._prevLastItemIndex = this.lastItemIndex;
      this._prevFirstVisibleIndex = this.firstVisibleIndex;
      this._prevLastVisibleIndex = this.lastVisibleIndex; // Clear the reset flag

      this._didReset = false;
    }
    /*
     * The scroll diff is the difference between where we want the container's scrollTop to be,
     * and where it actually is right now. By default it accounts for the `_prependOffset`, which
     * is set when items are added to the front of the collection, as well as any discrepancies
     * that may have arisen between the cached `_scrollTop` value and the actually container's
     * scrollTop. The container's scrollTop may be modified by the browser when we manipulate DOM
     * (Chrome specifically does this a lot), so `_scrollTop` should be considered the canonical
     * scroll top.
     *
     * Subclasses should override this method to provide any difference between expected item size
     * pre-render and actual item size post-render.
     */


    _calculateScrollDiff() {
      return this._prependOffset + this._scrollTop - this._scrollContainer.scrollTop;
    }

    _determineUpdateType() {
      const {
        items,
        key,
        totalItems,
        _prevTotalItems,
        _prevFirstKey,
        _prevLastKey
      } = this;
      const lenDiff = totalItems - _prevTotalItems;

      if (isPrepend(lenDiff, items, key, _prevFirstKey, _prevLastKey) === true) {
        this.prepend(lenDiff);
      } else if (isAppend(lenDiff, items, key, _prevFirstKey, _prevLastKey) === true) {
        this.append(lenDiff);
      } else {
        this.reset();
      }

      const firstItem = objectAt(this.items, 0);
      const lastItem = objectAt(this.items, this.totalItems - 1);
      this._prevTotalItems = totalItems;
      this._prevFirstKey = totalItems > 0 ? keyForItem(firstItem, key, 0) : 0;
      this._prevLastKey = totalItems > 0 ? keyForItem(lastItem, key, totalItems - 1) : 0;
    }

    _updateConstants() {
      const {
        estimateHeight,
        _occludedContentBefore,
        _itemContainer,
        _scrollContainer
      } = this;
      // it's measured height via bounding client rect will reflect the height with any transformations
      // applied. We use this to find out the scale of the items so we can store measurements at the
      // correct heights.

      const scrollContainerOffsetHeight = _scrollContainer.offsetHeight;

      const {
        height: scrollContainerRenderedHeight
      } = _scrollContainer.getBoundingClientRect();

      let transformScale; // transformScale represents the opposite of the scale, if any, applied to the collection. Check for equality
      // to guard against floating point errors, and check to make sure we're not dividing by zero (default to scale 1 if so)

      if (scrollContainerOffsetHeight === scrollContainerRenderedHeight || scrollContainerRenderedHeight === 0) {
        transformScale = 1;
      } else {
        transformScale = scrollContainerOffsetHeight / scrollContainerRenderedHeight;
      }

      const {
        top: scrollContentTop
      } = getScaledClientRect(_occludedContentBefore, transformScale);
      const {
        top: scrollContainerTop
      } = getScaledClientRect(_scrollContainer, transformScale);
      let scrollContainerMaxHeight = 0;

      if (_scrollContainer instanceof Element) {
        const maxHeightStyle = window.getComputedStyle(_scrollContainer).maxHeight;

        if (maxHeightStyle !== 'none') {
          scrollContainerMaxHeight = estimateElementHeight(_scrollContainer.parentElement, maxHeightStyle);
        }
      }

      const calculatedEstimateHeight = typeof estimateHeight === 'string' ? estimateElementHeight(_itemContainer, estimateHeight) : estimateHeight;
      this._transformScale = transformScale;
      this._calculatedEstimateHeight = calculatedEstimateHeight;
      this._calculatedScrollContainerHeight = roundTo(Math.max(scrollContainerOffsetHeight, scrollContainerMaxHeight)); // The offset between the top of the collection and the top of the scroll container. Determined by finding
      // the distance from the collection is from the top of the scroll container's content (scrollTop + actual position)
      // and subtracting the scroll containers actual top.

      this._collectionOffset = roundTo(_scrollContainer.scrollTop + scrollContentTop - scrollContainerTop);
    }
    /*
     * Updates virtualComponents, which is meant to be a static pool of components that we render to.
     * In order to decrease the time spent rendering and diffing, we pull the {{each}} out of the DOM
     * and only replace the content of _virtualComponents which are removed/added.
     *
     * For instance, if we start with the following and scroll down, items 2 and 3 do not need to be
     * rerendered, only item 1 needs to be removed and only item 4 needs to be added. So we replace
     * item 1 with item 4, and then manually move the DOM:
     *
     *   1                        4                         2
     *   2 -> replace 1 with 4 -> 2 -> manually move DOM -> 3
     *   3                        3                         4
     *
     * However, _virtualComponents is still out of order. Rather than keep track of the state of
     * things in _virtualComponents, we track the visually ordered components in the
     * _orderedComponents array. This is possible because all of our operations are relatively simple,
     * popping some number of components off one end and pushing them onto the other.
     *
     * @private
     */


    _updateVirtualComponents() {
      const {
        items,
        orderedComponents,
        virtualComponents,
        _componentPool,
        shouldRecycle,
        renderAll,
        _started,
        _didReset,
        _occludedContentBefore,
        _occludedContentAfter,
        totalItems
      } = this;
      let renderedFirstItemIndex, renderedLastItemIndex, renderedTotalBefore, renderedTotalAfter;

      if (renderAll === true) {
        // All items should be rendered, set indexes based on total item count
        renderedFirstItemIndex = 0;
        renderedLastItemIndex = totalItems - 1;
        renderedTotalBefore = 0;
        renderedTotalAfter = 0;
      } else if (_started === false) {
        // The Radar hasn't been started yet, render the initialRenderCount if it exists
        renderedFirstItemIndex = this.startingIndex;
        renderedLastItemIndex = this.startingIndex + this.initialRenderCount - 1;
        renderedTotalBefore = 0;
        renderedTotalAfter = 0;
      } else {
        renderedFirstItemIndex = this.firstItemIndex;
        renderedLastItemIndex = this.lastItemIndex;
        renderedTotalBefore = this.totalBefore;
        renderedTotalAfter = this.totalAfter;
      } // If there are less items available than rendered, we drop the last rendered item index


      renderedLastItemIndex = Math.min(renderedLastItemIndex, totalItems - 1); // Add components to be recycled to the pool

      while (orderedComponents.length > 0 && orderedComponents[0].index < renderedFirstItemIndex) {
        _componentPool.push(orderedComponents.shift());
      }

      while (orderedComponents.length > 0 && orderedComponents[orderedComponents.length - 1].index > renderedLastItemIndex) {
        _componentPool.unshift(orderedComponents.pop());
      }

      if (_didReset) {
        if (shouldRecycle === true) {
          for (let i = 0; i < orderedComponents.length; i++) {
            // If the underlying array has changed, the indexes could be the same but
            // the content may have changed, so recycle the remaining components
            const component = orderedComponents[i];
            component.recycle(objectAt(items, component.index), component.index);
          }
        } else {
          while (orderedComponents.length > 0) {
            // If recycling is disabled we need to delete all components and clear the array
            _componentPool.push(orderedComponents.shift());
          }
        }
      }

      let firstIndexInList = orderedComponents.length > 0 ? orderedComponents[0].index : renderedFirstItemIndex;
      let lastIndexInList = orderedComponents.length > 0 ? orderedComponents[orderedComponents.length - 1].index : renderedFirstItemIndex - 1; // Append as many items as needed to the rendered components

      while (lastIndexInList < renderedLastItemIndex) {
        let component;

        if (shouldRecycle === true) {
          component = _componentPool.pop() || new VirtualComponent();
        } else {
          component = new VirtualComponent();
        }

        const itemIndex = ++lastIndexInList;
        component.recycle(objectAt(items, itemIndex), itemIndex);

        this._appendComponent(component);

        orderedComponents.push(component);
      } // Prepend as many items as needed to the rendered components


      while (firstIndexInList > renderedFirstItemIndex) {
        let component;

        if (shouldRecycle === true) {
          component = _componentPool.pop() || new VirtualComponent();
        } else {
          component = new VirtualComponent();
        }

        const itemIndex = --firstIndexInList;
        component.recycle(objectAt(items, itemIndex), itemIndex);

        this._prependComponent(component);

        orderedComponents.unshift(component);
      } // If there are any items remaining in the pool, remove them


      if (_componentPool.length > 0) {
        if (shouldRecycle === true) {
          // Grab the DOM of the remaining components and move it to temporary node disconnected from
          // the body. If we end up using these components again, we'll grab their DOM and put it back
          for (let i = 0; i < _componentPool.length; i++) {
            const component = _componentPool[i];
            insertRangeBefore(this._domPool, null, component.realUpperBound, component.realLowerBound);
          }
        } else {
          virtualComponents.removeObjects(_componentPool);
          _componentPool.length = 0;
        }
      }

      const totalItemsBefore = renderedFirstItemIndex;
      const totalItemsAfter = totalItems - renderedLastItemIndex - 1;
      const beforeItemsText = totalItemsBefore === 1 ? 'item' : 'items';
      const afterItemsText = totalItemsAfter === 1 ? 'item' : 'items'; // Set padding element heights.

      _occludedContentBefore.style.height = Math.max(renderedTotalBefore, 0) + "px";
      _occludedContentBefore.innerHTML = totalItemsBefore > 0 ? "And " + totalItemsBefore + " " + beforeItemsText + " before" : '';
      _occludedContentAfter.style.height = Math.max(renderedTotalAfter, 0) + "px";
      _occludedContentAfter.innerHTML = totalItemsAfter > 0 ? "And " + totalItemsAfter + " " + afterItemsText + " after" : '';
    }

    _appendComponent(component) {
      const {
        virtualComponents,
        _occludedContentAfter,
        _itemContainer
      } = this;
      const relativeNode = _occludedContentAfter.realUpperBound;

      if (component.rendered === true) {
        insertRangeBefore(_itemContainer, relativeNode, component.realUpperBound, component.realLowerBound);
      } else {
        virtualComponents.insertAt(virtualComponents.get('length') - 1, component);
        component.rendered = true;
      }
    }

    _prependComponent(component) {
      const {
        virtualComponents,
        _occludedContentBefore,
        _prependComponentPool,
        _itemContainer
      } = this;
      const relativeNode = _occludedContentBefore.realLowerBound.nextSibling;

      if (component.rendered === true) {
        insertRangeBefore(_itemContainer, relativeNode, component.realUpperBound, component.realLowerBound);
      } else {
        virtualComponents.insertAt(virtualComponents.get('length') - 1, component);
        component.rendered = true; // Components that are both new and prepended still need to be rendered at the end because Glimmer.
        // We have to move them _after_ they render, so we schedule that if they exist

        _prependComponentPool.unshift(component);

        if (this._nextLayout === null) {
          this._nextLayout = this.schedule('layout', () => {
            this._nextLayout = null;

            while (_prependComponentPool.length > 0) {
              const component = _prependComponentPool.pop(); // Changes with each inserted component


              const relativeNode = _occludedContentBefore.realLowerBound.nextSibling;
              insertRangeBefore(_itemContainer, relativeNode, component.realUpperBound, component.realLowerBound);
            }
          });
        }
      }
    }

    _sendActions() {
      const {
        firstItemIndex,
        lastItemIndex,
        firstVisibleIndex,
        lastVisibleIndex,
        _prevFirstVisibleIndex,
        _prevLastVisibleIndex,
        totalItems,
        _firstReached,
        _lastReached,
        _didReset
      } = this;

      if (_didReset || firstVisibleIndex !== _prevFirstVisibleIndex) {
        this.sendAction('firstVisibleChanged', firstVisibleIndex);
      }

      if (_didReset || lastVisibleIndex !== _prevLastVisibleIndex) {
        this.sendAction('lastVisibleChanged', lastVisibleIndex);
      }

      if (_firstReached === false && firstItemIndex === 0) {
        this.sendAction('firstReached', firstItemIndex);
        this._firstReached = true;
      }

      if (_lastReached === false && lastItemIndex === totalItems - 1) {
        this.sendAction('lastReached', lastItemIndex);
        this._lastReached = true;
      }
    }

    prepend(numPrepended) {
      this._prevFirstItemIndex += numPrepended;
      this._prevLastItemIndex += numPrepended;
      this.orderedComponents.forEach(c => Ember.set(c, 'index', Ember.get(c, 'index') + numPrepended));
      this._firstReached = false;
      this._prependOffset = numPrepended * this._calculatedEstimateHeight;
    }

    append() {
      this._lastReached = false;
    }

    reset() {
      this._firstReached = false;
      this._lastReached = false;
      this._didReset = true;
    }

    pageUp() {
      if (this.renderAll) {
        return; // All items rendered, no need to page up
      }

      const {
        bufferSize,
        firstItemIndex,
        totalComponents
      } = this;

      if (firstItemIndex !== 0) {
        const newFirstItemIndex = Math.max(firstItemIndex - totalComponents + bufferSize, 0);
        const offset = this.getOffsetForIndex(newFirstItemIndex);
        this._scrollContainer.scrollTop = offset + this._collectionOffset;
        this.scheduleUpdate();
      }
    }

    pageDown() {
      if (this.renderAll) {
        return; // All items rendered, no need to page down
      }

      const {
        bufferSize,
        lastItemIndex,
        totalComponents,
        totalItems
      } = this;

      if (lastItemIndex !== totalItems - 1) {
        const newFirstItemIndex = Math.min(lastItemIndex + bufferSize + 1, totalItems - totalComponents);
        const offset = this.getOffsetForIndex(newFirstItemIndex);
        this._scrollContainer.scrollTop = offset + this._collectionOffset;
        this.scheduleUpdate();
      }
    }

    get totalComponents() {
      return Math.min(this.totalItems, this.lastItemIndex - this.firstItemIndex + 1);
    }
    /*
     * `prependOffset` exists because there are times when we need to do the following in this exact
     * order:
     *
     * 1. Prepend, which means we need to adjust the scroll position by `estimateHeight * numPrepended`
     * 2. Calculate the items that will be displayed after the prepend, and move VCs around as
     *    necessary (`scheduleUpdate`).
     * 3. Actually add the amount prepended to `scrollContainer.scrollTop`
     *
     * This is due to some strange behavior in Chrome where it will modify `scrollTop` on it's own
     * when prepending item elements. We seem to avoid this behavior by doing these things in a RAF
     * in this exact order.
     */


    get visibleTop() {
      return Math.max(this._scrollTop - this._collectionOffset + this._prependOffset, 0);
    }

    get visibleMiddle() {
      return this.visibleTop + this._calculatedScrollContainerHeight / 2;
    }

    get visibleBottom() {
      // There is a case where the container of this vertical collection could have height 0 at
      // initial render step but will be updated later. We want to return visibleBottom to be 0 rather
      // than -1.
      return Math.max(this.visibleTop + this._calculatedScrollContainerHeight - 1, 0);
    }

    get totalItems() {
      return this.items ? Ember.get(this.items, 'length') : 0;
    }

  }

  /*
   * `SkipList` is a data structure designed with two main uses in mind:
   *
   * - Given a target value, find the index i in the list such that
   * `sum(list[0]..list[i]) <= value < sum(list[0]..list[i + 1])`
   *
   * - Given the index i (the fulcrum point) from above, get `sum(list[0]..list[i])`
   *   and `sum(list[i + 1]..list[-1])`
   *
   * The idea is that given a list of arbitrary heights or widths in pixels, we want to find
   * the index of the item such that when all of the items before it are added together, it will
   * be as close to the target (scrollTop of our container) as possible.
   *
   * This data structure acts somewhat like a Binary Search Tree. Given a list of size n, the
   * retreival time for the index is O(log n) and the update time should any values change is
   * O(log n). The space complexity is O(n log n) in bytes (using Float32Arrays helps a lot
   * here), and the initialization time is O(n log n).
   *
   * It works by constructing layer arrays, each of which is setup such that
   * `layer[i] = prevLayer[i * 2] + prevLayer[(i * 2) + 1]`. This allows us to traverse the layers
   * downward using a binary search to arrive at the index we want. We also add the values up as we
   * traverse to get the total value before and after the final index.
   */

  function fill(array, value, start, end) {
    if (start === void 0) {
      start = 0;
    }

    if (end === void 0) {
      end = array.length;
    }

    if (typeof array.fill === 'function') {
      array.fill(value, start, end);
    } else {
      for (; start < end; start++) {
        array[start] = value;
      }

      return array;
    }
  }

  function subarray(array, start, end) {
    if (typeof array.subarray === 'function') {
      return array.subarray(start, end);
    } else {
      return array.slice(start, end);
    }
  }

  class SkipList {
    constructor(length, defaultValue) {
      const values = new Float32Array(new ArrayBuffer(length * 4));
      fill(values, defaultValue);
      this.length = length;
      this.defaultValue = defaultValue;

      this._initializeLayers(values, defaultValue);
    }

    _initializeLayers(values, defaultValue) {
      const layers = [values];
      let i, length, layer, prevLayer, left, right;
      prevLayer = layer = values;
      length = values.length;

      while (length > 2) {
        length = Math.ceil(length / 2);
        layer = new Float32Array(new ArrayBuffer(length * 4));

        if (defaultValue !== undefined) {
          // If given a default value we assume that we can fill each
          // layer of the skip list with the previous layer's value * 2.
          // This allows us to use the `fill` method on Typed arrays, which
          // an order of magnitude faster than manually calculating each value.
          defaultValue = defaultValue * 2;
          fill(layer, defaultValue);
          left = prevLayer[(length - 1) * 2] || 0;
          right = prevLayer[(length - 1) * 2 + 1] || 0; // Layers are not powers of 2, and sometimes they may by odd sizes.
          // Only the last value of a layer will be different, so we calculate
          // its value manually.

          layer[length - 1] = left + right;
        } else {
          for (i = 0; i < length; i++) {
            left = prevLayer[i * 2];
            right = prevLayer[i * 2 + 1];
            layer[i] = right ? left + right : left;
          }
        }

        layers.unshift(layer);
        prevLayer = layer;
      }

      this.total = layer.length > 0 ? layer.length > 1 ? layer[0] + layer[1] : layer[0] : 0;
      this.layers = layers;
      this.values = values;
    }

    find(targetValue) {
      const {
        layers,
        total,
        length,
        values
      } = this;
      const numLayers = layers.length;

      if (length === 0) {
        return {
          index: 0,
          totalBefore: 0,
          totalAfter: 0
        };
      }

      let i, layer, left, leftIndex, rightIndex;
      let index = 0;
      let totalBefore = 0;
      let totalAfter = 0;
      targetValue = Math.min(total - 1, targetValue);

      for (i = 0; i < numLayers; i++) {
        layer = layers[i];
        leftIndex = index;
        rightIndex = index + 1;
        left = layer[leftIndex];

        if (targetValue >= totalBefore + left) {
          totalBefore = totalBefore + left;
          index = rightIndex * 2;
        } else {
          index = leftIndex * 2;
        }
      }

      index = index / 2;
      totalAfter = total - (totalBefore + values[index]);
      return {
        index,
        totalBefore,
        totalAfter
      };
    }

    getOffset(targetIndex) {
      const {
        layers,
        length,
        values
      } = this;
      const numLayers = layers.length;

      if (length === 0) {
        return 0;
      }

      let index = 0;
      let offset = 0;

      for (let i = 0; i < numLayers - 1; i++) {
        const layer = layers[i];
        const leftIndex = index;
        const rightIndex = index + 1;

        if (targetIndex >= rightIndex * Math.pow(2, numLayers - i - 1)) {
          offset = offset + layer[leftIndex];
          index = rightIndex * 2;
        } else {
          index = leftIndex * 2;
        }
      }

      if (index + 1 === targetIndex) {
        offset += values[index];
      }

      return offset;
    }

    set(index, value) {
      const {
        layers
      } = this;
      const oldValue = layers[layers.length - 1][index];
      const delta = roundTo(value - oldValue);

      if (delta === 0) {
        return delta;
      }

      let i, layer;

      for (i = layers.length - 1; i >= 0; i--) {
        layer = layers[i];
        layer[index] += delta;
        index = Math.floor(index / 2);
      }

      this.total += delta;
      return delta;
    }

    prepend(numPrepended) {
      const {
        values: oldValues,
        length: oldLength,
        defaultValue
      } = this;
      const newLength = numPrepended + oldLength;
      const newValues = new Float32Array(new ArrayBuffer(newLength * 4));
      newValues.set(oldValues, numPrepended);
      fill(newValues, defaultValue, 0, numPrepended);
      this.length = newLength;

      this._initializeLayers(newValues);
    }

    append(numAppended) {
      const {
        values: oldValues,
        length: oldLength,
        defaultValue
      } = this;
      const newLength = numAppended + oldLength;
      const newValues = new Float32Array(new ArrayBuffer(newLength * 4));
      newValues.set(oldValues);
      fill(newValues, defaultValue, oldLength);
      this.length = newLength;

      this._initializeLayers(newValues);
    }

    reset(newLength) {
      const {
        values: oldValues,
        length: oldLength,
        defaultValue
      } = this;

      if (oldLength === newLength) {
        return;
      }

      const newValues = new Float32Array(new ArrayBuffer(newLength * 4));

      if (oldLength < newLength) {
        newValues.set(oldValues);
        fill(newValues, defaultValue, oldLength);
      } else {
        newValues.set(subarray(oldValues, 0, newLength));
      }

      this.length = newLength;

      if (oldLength === 0) {
        this._initializeLayers(newValues, defaultValue);
      } else {
        this._initializeLayers(newValues);
      }
    }

  }

  class DynamicRadar extends Radar {
    constructor(parentToken, options) {
      super(parentToken, options);
      this._firstItemIndex = 0;
      this._lastItemIndex = 0;
      this._totalBefore = 0;
      this._totalAfter = 0;
      this._minHeight = Infinity;
      this._nextIncrementalRender = null;
      this.skipList = null;
    }

    destroy() {
      super.destroy();
      this.skipList = null;
    }

    scheduleUpdate(didUpdateItems) {
      // Cancel incremental render check, since we'll be remeasuring anyways
      if (this._nextIncrementalRender !== null) {
        this._nextIncrementalRender.cancel();

        this._nextIncrementalRender = null;
      }

      super.scheduleUpdate(didUpdateItems);
    }

    afterUpdate() {
      // Schedule a check to see if we should rerender
      if (this._nextIncrementalRender === null && this._nextUpdate === null) {
        this._nextIncrementalRender = this.schedule('sync', () => {
          this._nextIncrementalRender = null;

          if (this._shouldScheduleRerender()) {
            this.update();
          }
        });
      }

      super.afterUpdate();
    }

    _updateConstants() {
      super._updateConstants();

      if (this._calculatedEstimateHeight < this._minHeight) {
        this._minHeight = this._calculatedEstimateHeight;
      } // Create the SkipList only after the estimateHeight has been calculated the first time


      if (this.skipList === null) {
        this.skipList = new SkipList(this.totalItems, this._calculatedEstimateHeight);
      } else {
        this.skipList.defaultValue = this._calculatedEstimateHeight;
      }
    }

    _updateIndexes() {
      const {
        bufferSize,
        skipList,
        visibleTop,
        visibleBottom,
        totalItems,
        _didReset
      } = this;

      if (totalItems === 0) {
        this._firstItemIndex = 0;
        this._lastItemIndex = -1;
        this._totalBefore = 0;
        this._totalAfter = 0;
        return;
      } // Don't measure if the radar has just been instantiated or reset, as we are rendering with a
      // completely new set of items and won't get an accurate measurement until after they render the
      // first time.


      if (_didReset === false) {
        this._measure();
      }

      const {
        values
      } = skipList;
      let {
        totalBefore,
        index: firstVisibleIndex
      } = this.skipList.find(visibleTop);
      let {
        totalAfter,
        index: lastVisibleIndex
      } = this.skipList.find(visibleBottom);
      const maxIndex = totalItems - 1;
      let firstItemIndex = firstVisibleIndex;
      let lastItemIndex = lastVisibleIndex; // Add buffers

      for (let i = bufferSize; i > 0 && firstItemIndex > 0; i--) {
        firstItemIndex--;
        totalBefore -= values[firstItemIndex];
      }

      for (let i = bufferSize; i > 0 && lastItemIndex < maxIndex; i--) {
        lastItemIndex++;
        totalAfter -= values[lastItemIndex];
      }

      this._firstItemIndex = firstItemIndex;
      this._lastItemIndex = lastItemIndex;
      this._totalBefore = totalBefore;
      this._totalAfter = totalAfter;
    }

    _calculateScrollDiff() {
      const {
        firstItemIndex,
        _prevFirstVisibleIndex,
        _prevFirstItemIndex
      } = this;
      let beforeVisibleDiff = 0;

      if (firstItemIndex < _prevFirstItemIndex) {
        // Measurement only items that could affect scrollTop. This will necesarilly be the
        // minimum of the either the total number of items that are rendered up to the first
        // visible item, OR the number of items that changed before the first visible item
        // (the delta). We want to measure the delta of exactly this number of items, because
        // items that are after the first visible item should not affect the scroll position,
        // and neither should items already rendered before the first visible item.
        const measureLimit = Math.min(Math.abs(firstItemIndex - _prevFirstItemIndex), _prevFirstVisibleIndex - firstItemIndex);
        beforeVisibleDiff = Math.round(this._measure(measureLimit));
      }

      return beforeVisibleDiff + super._calculateScrollDiff();
    }

    _shouldScheduleRerender() {
      const {
        firstItemIndex,
        lastItemIndex
      } = this;

      this._updateConstants();

      this._measure(); // These indexes could change after the measurement, and in the incremental render
      // case we want to check them _after_ the change.


      const {
        firstVisibleIndex,
        lastVisibleIndex
      } = this;
      return firstVisibleIndex < firstItemIndex || lastVisibleIndex > lastItemIndex;
    }

    _measure(measureLimit) {
      if (measureLimit === void 0) {
        measureLimit = null;
      }

      const {
        orderedComponents,
        skipList,
        _occludedContentBefore,
        _transformScale
      } = this;
      const numToMeasure = measureLimit !== null ? Math.min(measureLimit, orderedComponents.length) : orderedComponents.length;
      let totalDelta = 0;

      for (let i = 0; i < numToMeasure; i++) {
        const currentItem = orderedComponents[i];
        const previousItem = orderedComponents[i - 1];
        const itemIndex = currentItem.index;
        const {
          top: currentItemTop,
          height: currentItemHeight
        } = getScaledClientRect(currentItem, _transformScale);
        let margin;

        if (previousItem !== undefined) {
          margin = currentItemTop - getScaledClientRect(previousItem, _transformScale).bottom;
        } else {
          margin = currentItemTop - getScaledClientRect(_occludedContentBefore, _transformScale).bottom;
        }

        const newHeight = roundTo(currentItemHeight + margin);
        const itemDelta = skipList.set(itemIndex, newHeight);

        if (newHeight < this._minHeight) {
          this._minHeight = newHeight;
        }

        if (itemDelta !== 0) {
          totalDelta += itemDelta;
        }
      }

      return totalDelta;
    }

    _didEarthquake(scrollDiff) {
      return scrollDiff > this._minHeight / 2;
    }

    get total() {
      return this.skipList.total;
    }

    get totalBefore() {
      return this._totalBefore;
    }

    get totalAfter() {
      return this._totalAfter;
    }

    get firstItemIndex() {
      return this._firstItemIndex;
    }

    get lastItemIndex() {
      return this._lastItemIndex;
    }

    get firstVisibleIndex() {
      const {
        visibleTop
      } = this;
      const {
        index
      } = this.skipList.find(visibleTop);
      return index;
    }

    get lastVisibleIndex() {
      const {
        visibleBottom,
        totalItems
      } = this;
      const {
        index
      } = this.skipList.find(visibleBottom);
      return Math.min(index, totalItems - 1);
    }

    prepend(numPrepended) {
      super.prepend(numPrepended);
      this.skipList.prepend(numPrepended);
    }

    append(numAppended) {
      super.append(numAppended);
      this.skipList.append(numAppended);
    }

    reset() {
      super.reset();
      this.skipList.reset(this.totalItems);
    }
    /*
     * Public API to query the skiplist for the offset of an item
     */


    getOffsetForIndex(index) {
      this._measure();

      return this.skipList.getOffset(index);
    }

  }

  class StaticRadar extends Radar {
    constructor(parentToken, options) {
      super(parentToken, options);
      this._firstItemIndex = 0;
      this._lastItemIndex = 0;
    }

    _updateIndexes() {
      const {
        bufferSize,
        totalItems,
        visibleMiddle,
        _calculatedEstimateHeight,
        _calculatedScrollContainerHeight
      } = this;

      if (totalItems === 0) {
        this._firstItemIndex = 0;
        this._lastItemIndex = -1;
        return;
      }

      const maxIndex = totalItems - 1;
      const middleItemIndex = Math.floor(visibleMiddle / _calculatedEstimateHeight);
      const shouldRenderCount = Math.min(Math.ceil(_calculatedScrollContainerHeight / _calculatedEstimateHeight), totalItems);
      let firstItemIndex = middleItemIndex - Math.floor(shouldRenderCount / 2);
      let lastItemIndex = middleItemIndex + Math.ceil(shouldRenderCount / 2) - 1;

      if (firstItemIndex < 0) {
        firstItemIndex = 0;
        lastItemIndex = shouldRenderCount - 1;
      }

      if (lastItemIndex > maxIndex) {
        lastItemIndex = maxIndex;
        firstItemIndex = maxIndex - (shouldRenderCount - 1);
      }

      firstItemIndex = Math.max(firstItemIndex - bufferSize, 0);
      lastItemIndex = Math.min(lastItemIndex + bufferSize, maxIndex);
      this._firstItemIndex = firstItemIndex;
      this._lastItemIndex = lastItemIndex;
    }

    _didEarthquake(scrollDiff) {
      return scrollDiff > this._calculatedEstimateHeight / 2;
    }

    get total() {
      return this.totalItems * this._calculatedEstimateHeight;
    }

    get totalBefore() {
      return this.firstItemIndex * this._calculatedEstimateHeight;
    }

    get totalAfter() {
      return this.total - (this.lastItemIndex + 1) * this._calculatedEstimateHeight;
    }

    get firstItemIndex() {
      return this._firstItemIndex;
    }

    get lastItemIndex() {
      return this._lastItemIndex;
    }

    get firstVisibleIndex() {
      return Math.ceil(this.visibleTop / this._calculatedEstimateHeight);
    }

    get lastVisibleIndex() {
      return Math.min(Math.ceil(this.visibleBottom / this._calculatedEstimateHeight), this.totalItems) - 1;
    }
    /*
     * Public API to query for the offset of an item
     */


    getOffsetForIndex(index) {
      return index * this._calculatedEstimateHeight + 1;
    }

  }

  exports.DynamicRadar = DynamicRadar;
  exports.ScrollHandler = ScrollHandler;
  exports.StaticRadar = StaticRadar;
  exports.ViewportContainer = ViewportContainer$1;
  exports.addScrollHandler = addScrollHandler;
  exports.closestElement = closest;
  exports.keyForItem = keyForItem;
  exports.objectAt = objectAt;
  exports.removeScrollHandler = removeScrollHandler;

  Object.defineProperty(exports, '__esModule', { value: true });

});
