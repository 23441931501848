define("ember-bootstrap/templates/components/bs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BeigXoc5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[7,\"i\",true],[11,\"class\",[22,\"icon\"]],[8],[9],[0,\" \"]],\"parameters\":[]},null],[1,[22,\"text\"],false],[14,1,[[28,\"hash\",null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[24,[\"isFulfilled\"]],[24,[\"isPending\"]],[24,[\"isRejected\"]],[24,[\"isSettled\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button.hbs"
    }
  });

  _exports.default = _default;
});