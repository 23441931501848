define("ember-cp-validations/utils/meta-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let id = 0;
  const dataKey = symbol('data');

  function symbol(key) {
    return "_".concat(key, "_").concat(new Date().getTime(), "_").concat(id++);
  }

  function getData(obj, s) {
    let m = Ember.meta(obj);
    let data = m[dataKey];

    if (data) {
      return data[s];
    }
  }

  function setData(obj, s, value) {
    let m = Ember.meta(obj);
    let data = m[dataKey] = m[dataKey] || {};
    data[s] = value;
  }

  var _default = {
    symbol,
    getData,
    setData
  };
  _exports.default = _default;
});