define('ember-bootstrap-cp-validations/components/bs-form/element', ['exports', 'ember-bootstrap/components/bs-form/element'], function (exports, _element) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _element.default.extend({
    _attrValidations: null,
    notValidating: Ember.computed.not('isValidating').readOnly(),
    notDisabled: Ember.computed.not('disabled').readOnly(),
    _presenceEnabled: Ember.computed.not('_attrValidations.options.presence.disabled'),

    // Overwrite
    hasValidator: Ember.computed.notEmpty('_attrValidations').readOnly(),
    hasErrors: Ember.computed.and('_attrValidations.isInvalid', 'notValidating').readOnly(),
    isValidating: Ember.computed.readOnly('_attrValidations.isValidating'),

    // mark as required only if:
    // - field is not disabled,
    // - presence validator requires presence
    // - presence validator is enabled
    required: Ember.computed.and('notDisabled', '_attrValidations.options.presence.presence', '_presenceEnabled'),

    setupValidations() {
      Ember.defineProperty(this, '_attrValidations', Ember.computed.readOnly(`model.validations.attrs.${this.get('property')}`));
      Ember.defineProperty(this, 'errors', Ember.computed.readOnly('_attrValidations.messages'));
      Ember.defineProperty(this, 'warnings', Ember.computed.readOnly('_attrValidations.warningMessages'));
    }
  });
});