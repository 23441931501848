define('ember-keyboard/utils/generate-code-map', ['exports', 'ember-keyboard/fixtures/code-maps/default', 'ember-keyboard/fixtures/code-maps/mac-safari-and-chrome', 'ember-keyboard/fixtures/code-maps/gecko', 'ember-keyboard/fixtures/code-maps/gecko/linux', 'ember-keyboard/fixtures/code-maps/gecko/mac', 'ember-keyboard/fixtures/code-maps/chromium/linux'], function (exports, _default, _macSafariAndChrome, _gecko, _linux, _mac, _linux2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = generateCodeMap;
  function generateCodeMap(platform = '', product = '') {
    const isGecko = product.indexOf('Gecko') > -1;
    const isChromium = product.indexOf('Chromium') > -1;
    const isChrome = product.indexOf('Chrome') > -1;
    const isSafari = product.indexOf('Safari') > -1;
    const isLinux = platform.indexOf('Linux') > -1;
    const isMac = platform.indexOf('Mac') > -1;

    const codeMap = Ember.assign({}, _default.default);

    if (isGecko) {
      Ember.assign(codeMap, _gecko.default);

      if (isLinux) {
        Ember.assign(codeMap, _linux.default);
      } else if (isMac) {
        Ember.assign(codeMap, _mac.default);
      }
    } else if (isChromium && isLinux) {
      Ember.assign(codeMap, _linux2.default);
    } else if (isMac && (isSafari || isChrome)) {
      Ember.assign(codeMap, _macSafariAndChrome.default);
    }

    return codeMap;
  }
});