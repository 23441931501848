define("ember-sortable/components/sortable-group", ["exports", "ember-sortable/templates/components/sortable-group"], function (_exports, _sortableGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const a = Ember.A;
  const NO_MODEL = {};

  var _default = Ember.Component.extend({
    layout: _sortableGroup.default,
    attributeBindings: ['data-test-selector'],

    /**
      @property direction
      @type string
      @default y
    */
    direction: 'y',

    /**
      @property model
      @type Any
      @default null
    */
    model: NO_MODEL,

    /**
      @property items
      @type Ember.NativeArray
    */
    items: Ember.computed(() => a()),

    /**
      Position for the first item.
      If spacing is present, first item's position will have to change as well.
      @property itemPosition
      @type Number
    */
    itemPosition: Ember.computed(function () {
      let direction = this.get('direction');
      return this.get("sortedItems.firstObject.".concat(direction)) - this.get('sortedItems.firstObject.spacing');
    }).volatile(),

    /**
      @property sortedItems
      @type Array
    */
    sortedItems: Ember.computed(function () {
      let items = a(this.get('items'));
      let direction = this.get('direction');
      return a(items.sortBy(direction));
    }).volatile(),

    /**
      Register an item with this group.
      @method registerItem
      @param {SortableItem} [item]
    */
    registerItem(item) {
      this.get('items').addObject(item);
    },

    /**
      De-register an item with this group.
      @method deregisterItem
      @param {SortableItem} [item]
    */
    deregisterItem(item) {
      this.get('items').removeObject(item);
    },

    /**
      Prepare for sorting.
      Main purpose is to stash the current itemPosition so
      we don’t incur expensive re-layouts.
      @method prepare
    */
    prepare() {
      this._itemPosition = this.get('itemPosition');
    },

    /**
      Update item positions (relatively to the first element position).
      @method update
    */
    update() {
      let sortedItems = this.get('sortedItems'); // Position of the first element

      let position = this._itemPosition; // Just in case we haven’t called prepare first.

      if (position === undefined) {
        position = this.get('itemPosition');
      }

      sortedItems.forEach(item => {
        let dimension;
        let direction = this.get('direction');

        if (!Ember.get(item, 'isDragging')) {
          Ember.set(item, direction, position);
        } // add additional spacing around active element


        if (Ember.get(item, 'isBusy')) {
          position += Ember.get(item, 'spacing') * 2;
        }

        if (direction === 'x') {
          dimension = 'width';
        }

        if (direction === 'y') {
          dimension = 'height';
        }

        position += Ember.get(item, dimension);
      });
    },

    /**
      @method commit
    */
    commit() {
      let items = this.get('sortedItems');
      let groupModel = this.get('model');
      let itemModels = items.mapBy('model');
      let draggedItem = items.findBy('wasDropped', true);
      let draggedModel;

      if (draggedItem) {
        Ember.set(draggedItem, 'wasDropped', false); // Reset

        draggedModel = Ember.get(draggedItem, 'model');
      }

      delete this._itemPosition;
      Ember.run.schedule('render', () => {
        items.invoke('freeze');
      });
      Ember.run.schedule('afterRender', () => {
        items.invoke('reset');
      });
      Ember.run.next(() => {
        Ember.run.schedule('render', () => {
          items.invoke('thaw');
        });
      });

      if (groupModel !== NO_MODEL) {
        this.sendAction('onChange', groupModel, itemModels, draggedModel);
      } else {
        this.sendAction('onChange', itemModels, draggedModel);
      }
    }

  });

  _exports.default = _default;
});