define("ember-bootstrap/templates/components/bs-accordion/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cFTiohwU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h5\",true],[10,\"class\",\"mb-0\"],[8],[0,\"\\n  \"],[7,\"button\",true],[11,\"class\",[29,[\"btn btn-link \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[11,\"disabled\",[22,\"disabled\"]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/title.hbs"
    }
  });

  _exports.default = _default;
});