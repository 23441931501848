define("ember-date-components/helpers/is-equal-month", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqualMonth = isEqualMonth;
  _exports.default = void 0;

  function isEqualMonth(_ref) {
    let [m1, m2] = _ref;

    if (!_moment.default.isMoment(m1) || !_moment.default.isMoment(m2)) {
      return false;
    }

    return m1.format('YYYY-MM') === m2.format('YYYY-MM');
  }

  var _default = Ember.Helper.helper(isEqualMonth);

  _exports.default = _default;
});