define("ember-date-components/templates/components/date-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s7UEywBc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"date-time-picker__item date-time-picker__item--date\"],[8],[0,\"\\n  \"],[1,[28,\"date-picker\",null,[[\"value\",\"buttonClasses\",\"buttonDateFormat\",\"minDate\",\"maxDate\",\"disabled\",\"renderInPlace\",\"horizontalPosition\",\"verticalPosition\",\"action\"],[[24,[\"value\"]],[24,[\"datePickerClasses\"]],[24,[\"buttonDateFormat\"]],[24,[\"minDate\"]],[24,[\"maxDate\"]],[24,[\"disabled\"]],[24,[\"renderInPlace\"]],[24,[\"horizontalPosition\"]],[24,[\"verticalPosition\"]],[28,\"action\",[[23,0,[]],\"updateDate\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"date-time-picker__item date-time-picker__item--time\"],[8],[0,\"\\n  \"],[1,[28,\"time-picker\",null,[[\"value\",\"disabled\",\"minTime\",\"maxTime\",\"step\",\"selectStep\",\"amPm\",\"inputClasses\",\"renderInPlace\",\"horizontalPosition\",\"verticalPosition\",\"action\"],[[24,[\"timePickerValue\"]],[24,[\"timePickerDisabled\"]],[24,[\"minTime\"]],[24,[\"maxTime\"]],[24,[\"step\"]],[24,[\"selectStep\"]],[24,[\"amPm\"]],[24,[\"timePickerClasses\"]],[24,[\"renderInPlace\"]],[24,[\"horizontalPosition\"]],[24,[\"verticalPosition\"]],[28,\"action\",[[23,0,[]],\"updateTime\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-time-picker.hbs"
    }
  });

  _exports.default = _default;
});