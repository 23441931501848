define("ember-composable-helpers/helpers/object-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectAt = objectAt;
  _exports.default = void 0;

  function objectAt(index, array) {
    if (!Ember.isArray(array)) {
      return undefined;
    }

    index = parseInt(index, 10);
    return Ember.A(array).objectAt(index);
  }

  var _default = Ember.Helper.extend({
    content: Ember.computed('index', 'array.[]', function () {
      let index = Ember.get(this, 'index');
      let array = Ember.get(this, 'array');
      return objectAt(index, array);
    }),

    compute(_ref) {
      let [index, array] = _ref;
      Ember.set(this, 'index', index);
      Ember.set(this, 'array', array);
      return Ember.get(this, 'content');
    },

    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});