define("ember-cli-flash/utils/object-compact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectCompact;

  function objectCompact(objectInstance) {
    const compactedObject = {};

    for (let key in objectInstance) {
      const value = objectInstance[key];

      if (Ember.isPresent(value)) {
        compactedObject[key] = value;
      }
    }

    return compactedObject;
  }
});