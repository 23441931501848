define("ember-date-components/templates/components/date-picker-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wUrm8TeK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"date-picker__header__button date-picker__header__button--previous\"],[12,\"data-test-date-picker-goto-previous-month\",\"\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"gotoPreviousMonth\"]],[8],[0,\"\\n  <\\n\"],[9],[0,\"\\n\\n\"],[7,\"button\",false],[12,\"class\",\"date-picker__header__button date-picker__header__button--next\"],[12,\"data-test-date-picker-goto-next-month\",\"\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"gotoNextMonth\"]],[8],[0,\"\\n  >\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"date-picker__header__title\"],[11,\"data-test-date-picker-month\",[28,\"moment-format\",[[24,[\"currentMonth\"]],\"MM\"],null]],[11,\"data-test-date-picker-year\",[28,\"moment-format\",[[24,[\"currentMonth\"]],\"YYYY\"],null]],[8],[0,\"\\n\\n  \"],[1,[28,\"date-picker-month-year-select\",null,[[\"minDate\",\"maxDate\",\"currentMonth\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"gotoMonth\"],[[24,[\"minDate\"]],[24,[\"maxDate\"]],[24,[\"currentMonth\"]],[24,[\"disableMonthPicker\"]],[24,[\"disableYearPicker\"]],[24,[\"availableYearOffset\"]],[28,\"action\",[[23,0,[]],\"gotoMonth\"],null]]]],false],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-picker-navigation.hbs"
    }
  });

  _exports.default = _default;
});