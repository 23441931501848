define('ember-keyboard/mixins/ember-keyboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(Ember.Evented, {
    keyboardPriority: 0,

    keyboard: Ember.inject.service(),

    init(...args) {
      Ember.get(this, 'keyboard').register(this);

      return this._super(...args);
    },

    willDestroyElement(...args) {
      this._super(...args);

      Ember.get(this, 'keyboard').unregister(this);
    },

    willDestroy(...args) {
      this._super(...args);

      Ember.get(this, 'keyboard').unregister(this);
    }
  });
});