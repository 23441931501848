define("ember-bootstrap/templates/components/bs-accordion/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DckNGRkZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"title\",\"body\"],[[28,\"component\",[[24,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[24,[\"collapsed\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],[24,[\"onClick\"]],[24,[\"value\"]]],null]]]],[28,\"component\",[[24,[\"bodyComponent\"]]],[[\"collapsed\"],[[24,[\"collapsed\"]]]]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"component\",[[24,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[24,[\"collapsed\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],[24,[\"onClick\"]],[24,[\"value\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[22,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[24,[\"bodyComponent\"]]],[[\"collapsed\"],[[24,[\"collapsed\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/item.hbs"
    }
  });

  _exports.default = _default;
});