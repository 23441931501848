define("ember-bootstrap/templates/components/bs-form/element/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IeG1xElJ",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"showMultipleErrors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"pre-scrollable\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[22,\"feedbackClass\"]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[22,\"feedbackClass\"]],[8],[1,[24,[\"messages\",\"firstObject\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/errors.hbs"
    }
  });

  _exports.default = _default;
});