define("ember-content-placeholders/templates/components/content-placeholders-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gSOJeAnO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__logo\"]]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__content\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__item\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__item\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__item\"]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-content-placeholders/templates/components/content-placeholders-nav.hbs"
    }
  });

  _exports.default = _default;
});