define("ember-bootstrap/templates/components/bs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NnktmUCQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"unbound\",[[24,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"inDom\"]]],null,{\"statements\":[[4,\"component\",[[24,[\"elementComponent\"]]],[[\"id\",\"placement\",\"fade\",\"showHelp\",\"class\",\"renderInPlace\",\"popperTarget\",\"autoPlacement\",\"viewportElement\",\"viewportPadding\"],[[24,[\"overlayId\"]],[24,[\"placement\"]],[24,[\"fade\"]],[24,[\"showHelp\"]],[24,[\"class\"]],[24,[\"_renderInPlace\"]],[24,[\"triggerTargetElement\"]],[24,[\"autoPlacement\"]],[24,[\"viewportElement\"]],[24,[\"viewportPadding\"]]]],{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[28,\"hash\",null,[[\"close\"],[[28,\"action\",[[23,0,[]],\"close\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip.hbs"
    }
  });

  _exports.default = _default;
});