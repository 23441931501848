define("ember-composable-helpers/helpers/append", ["exports", "ember-composable-helpers/-private/create-multi-array-helper"], function (_exports, _createMultiArrayHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.append = append;
  _exports.default = void 0;

  function append() {
    for (var _len = arguments.length, dependentKeys = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeys[_key] = arguments[_key];
    }

    dependentKeys = dependentKeys || [];
    let arrayKeys = dependentKeys.map(dependentKey => {
      return "".concat(dependentKey, ".[]");
    });
    return Ember.computed(...arrayKeys, function () {
      let array = dependentKeys.map(dependentKey => {
        let value = Ember.get(this, dependentKey);
        return Ember.isArray(value) ? value.toArray() : [value];
      });
      return [].concat(...array);
    });
  }

  var _default = (0, _createMultiArrayHelper.default)(append);

  _exports.default = _default;
});