define("ember-date-components/components/time-picker-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is an extended {{input}} to send appropriate events for arrowUp/arrowDown.
   * It is also slightly changed to send an input-changed event when a key that is neither arrowUp/arrowDown, enter or escape
   * is pressed.
   *
   * @namespace EmberDateComponents
   * @class TimePickerInput
   * @extends Ember.TextField
   * @public
   */
  var _default = Ember.TextField.extend({
    classNames: [],
    attributeBindings: ['disabled', 'data-time-picker-input-instance', 'tabindex'],
    type: 'text',
    tabindex: -1,

    /**
     * If this is true, the time picker is disabled and the selected time cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    keyUp() {// overwrite default implementation
    },

    keyDown(event) {
      // Tab doesn't trigger keyUp, so we need to capture it in keyDown
      switch (event.key) {
        case 'Enter':
          return this._enter(event);

        case 'Escape':
          return this._escape(event);

        case 'ArrowUp':
          return this._arrowUp(event);

        case 'ArrowDown':
          return this._arrowDown(event);

        case 'Tab':
          return this._tab(event);
      }
    },

    input() {
      Ember.run.once(this, this.inputChange);
    },

    inputChange() {
      this._elementValueDidChange();

      let value = Ember.get(this, 'value');
      let action = Ember.get(this, 'input-change');
      return action(value, this);
    },

    _tab(event) {
      let action = Ember.get(this, 'tab');
      return action(this, event);
    },

    _arrowUp(event) {
      let action = Ember.get(this, 'arrow-up');
      return action(this, event);
    },

    _arrowDown(event) {
      let action = Ember.get(this, 'arrow-down');
      return action(this, event);
    },

    _escape(event) {
      let action = Ember.get(this, 'escape');
      return action(this, event);
    },

    _enter(event) {
      let action = Ember.get(this, 'enter');
      return action(this, event);
    }

  });

  _exports.default = _default;
});