define("ember-parachute/decorators/query-param", ["exports", "ember-parachute/-private/symbols", "ember-parachute/decorators/-private/query-params-for"], function (_exports, _symbols, _queryParamsFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParam;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function createDescriptor(desc, qpDefinition) {
    qpDefinition = qpDefinition || {};

    const descriptor = _objectSpread({}, desc, {
      finisher(klass) {
        (0, _queryParamsFor.addQueryParamFor)(klass, desc.key, qpDefinition);
        klass.reopen((0, _queryParamsFor.getQueryParamsFor)(klass).Mixin);
        const proto = klass.proto(); // Remove duplicate queryParams created by the multiple mixins

        if (Array.isArray(proto.queryParams)) {
          const queryParams = Ember.A([...proto.queryParams]);
          const parachuteQueryParams = queryParams.filterBy(_symbols.PARACHUTE_QPS, true); // Keep the newest one

          parachuteQueryParams.pop(); // Remove the old ones

          queryParams.removeObjects(parachuteQueryParams);
          proto.queryParams = queryParams.toArray();
        }

        return klass;
      }

    });

    if (desc.kind === 'field') {
      if (typeof desc.initializer === 'function') {
        qpDefinition.defaultValue = desc.initializer();
      }

      descriptor.initializer = function initializer() {
        return qpDefinition.defaultValue;
      };
    }

    return descriptor;
  }

  function queryParam(qpDefinition) {
    // Handle `@queryParam` usage
    if ("".concat(qpDefinition) === '[object Descriptor]') {
      return createDescriptor(qpDefinition);
    } // Handle `@queryParam()` usage


    return desc => createDescriptor(desc, qpDefinition);
  }
});