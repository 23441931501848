define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ctQWIJt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"_isOpen\"]]],null,{\"statements\":[[4,\"ember-popper\",null,[[\"id\",\"class\",\"ariaRole\",\"placement\",\"popperTarget\",\"renderInPlace\",\"popperContainer\",\"modifiers\"],[[28,\"concat\",[[24,[\"dropdownElementId\"]],\"__menu\"],null],[28,\"concat\",[\"dropdown-menu \",[24,[\"alignClass\"]],[28,\"if\",[[24,[\"isOpen\"]],\" show\"],null],\" \",[24,[\"class\"]]],null],[24,[\"ariaRole\"]],[24,[\"popperPlacement\"]],[24,[\"toggleElement\"]],[24,[\"_renderInPlace\"]],\"#ember-bootstrap-wormhole\",[24,[\"popperModifiers\"]]]],{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"item\",\"link-to\",\"divider\"],[[28,\"component\",[[24,[\"itemComponent\"]]],null],[28,\"component\",[[24,[\"linkToComponent\"]]],null],[28,\"component\",[[24,[\"dividerComponent\"]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });

  _exports.default = _default;
});