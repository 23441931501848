define("ember-light-table/components/lt-infinity", ["exports", "ember-light-table/templates/components/lt-infinity"], function (_exports, _ltInfinity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _ltInfinity.default,
    inViewport: Ember.inject.service(),
    classNames: ['lt-infinity'],
    scrollableContent: null,
    scrollBuffer: 50,

    didInsertElement() {
      this._super(...arguments);

      const options = {
        viewportSpy: true,
        viewportTolerance: {
          bottom: this.scrollBuffer
        },
        scrollableArea: this.scrollableContent
      };
      const {
        onEnter,
        onExit
      } = this.inViewport.watchElement(this.element, options);
      onEnter(this.didEnterViewport.bind(this));
      onExit(this.didExitViewport.bind(this));
    },

    willDestroyElement() {
      this.inViewport.stopWatching(this.element);
    },

    didEnterViewport() {
      this.enterViewport();
    },

    didExitViewport() {
      this.exitViewport();
    }

  });

  _exports.default = _default;
});