define("ember-bootstrap/templates/components/bs-form/element/control/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZO0qrU2z",
    "block": "{\"symbols\":[\"option\",\"index\",\"id\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"form-check\",[28,\"if\",[[24,[\"inline\"]],\" form-check-inline\"],null]]]],[8],[0,\"\\n\"],[4,\"with\",[[28,\"concat\",[[23,0,[\"elementId\"]],\"-\",[23,2,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"input\",true],[10,\"class\",\"form-check-input\"],[11,\"id\",[23,3,[]]],[11,\"checked\",[28,\"bs-eq\",[[23,1,[]],[24,[\"value\"]]],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onChange\"]],[23,1,[]]],null]],[11,\"name\",[22,\"name\"]],[11,\"required\",[22,\"required\"]],[11,\"disabled\",[22,\"disabled\"]],[11,\"autofocus\",[22,\"autofocus\"]],[11,\"tabindex\",[22,\"tabindex\"]],[11,\"form\",[22,\"form\"]],[11,\"title\",[22,\"title\"]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n      \"],[7,\"label\",true],[11,\"for\",[23,3,[]]],[10,\"class\",\"form-check-label\"],[8],[0,\"\\n\"],[4,\"if\",[[25,4]],null,{\"statements\":[[0,\"          \"],[14,4,[[23,1,[]],[23,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"optionLabelPath\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"get\",[[23,1,[]],[24,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/radio.hbs"
    }
  });

  _exports.default = _default;
});