define("ember-date-components/helpers/date-picker-day-classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datePickerDayClasses = datePickerDayClasses;
  _exports.default = void 0;

  function datePickerDayClasses(_ref, _ref2) {
    let [baseClass] = _ref;
    let {
      isToday = false,
      isSelected = false,
      isDisabled = false,
      isInRange = false
    } = _ref2;
    let isTodayClass = isToday ? " ".concat(baseClass, "--today") : '';
    let isSelectedClass = isSelected ? " ".concat(baseClass, "--selected") : '';
    let isDisabledClass = isDisabled ? " ".concat(baseClass, "--disabled") : '';
    let isInRangeClass = isInRange ? " ".concat(baseClass, "--in-range") : '';
    return "".concat(baseClass).concat(isTodayClass).concat(isSelectedClass).concat(isDisabledClass).concat(isInRangeClass);
  }

  var _default = Ember.Helper.helper(datePickerDayClasses);

  _exports.default = _default;
});