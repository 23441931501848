define("ember-date-components/templates/components/date-picker-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DINDrEZM",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"date-picker date-picker--inline \",[28,\"if\",[[24,[\"options\"]],\" date-picker--options\"],null]]]],[11,\"style\",[22,\"translateX\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"date-picker__calendar\"],[8],[0,\"\\n\\n    \"],[1,[28,\"date-picker-navigation\",null,[[\"currentMonth\",\"minDate\",\"maxDate\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"class\",\"updateMonth\"],[[24,[\"currentMonth\"]],[24,[\"minDate\"]],[24,[\"maxDate\"]],[24,[\"disableMonthPicker\"]],[24,[\"disableYearPicker\"]],[24,[\"availableYearOffset\"]],\"date-picker__header\",[28,\"action\",[[23,0,[]],\"gotoMonth\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"date-picker-month\",null,[[\"month\",\"selectedDates\",\"selectDate\",\"minDate\",\"maxDate\",\"startWeekOnSunday\"],[[24,[\"currentMonth\"]],[24,[\"selectedDates\"]],[28,\"action\",[[23,0,[]],\"selectDate\"],null],[24,[\"minDate\"]],[24,[\"maxDate\"]],[24,[\"startWeekOnSunday\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"date-picker__options\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"_options\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",[29,[\"date-picker__options__button \",[23,1,[\"classes\"]]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,1,[\"action\"]],[23,1,[\"actionValue\"]]]],[8],[0,\"\\n          \"],[1,[23,1,[\"label\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-picker-inline.hbs"
    }
  });

  _exports.default = _default;
});