define("ember-bootstrap/templates/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vSxJojZS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"hasSubmitButton\"]]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[24,[\"buttonComponent\"]]],[[\"onClick\"],[[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]]],{\"statements\":[[1,[22,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"component\",[[24,[\"buttonComponent\"]]],[[\"type\",\"onClick\",\"disabled\"],[[24,[\"submitButtonType\"]],[28,\"action\",[[23,0,[]],[24,[\"onSubmit\"]]],null],[24,[\"submitDisabled\"]]]],{\"statements\":[[1,[22,\"submitTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[24,[\"buttonComponent\"]]],[[\"type\",\"onClick\"],[\"primary\",[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]]],{\"statements\":[[1,[22,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/footer.hbs"
    }
  });

  _exports.default = _default;
});