define("ember-cli-string-helpers/utils/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = titleize;

  function titleize() {
    let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    if (typeof string !== 'string') {
      throw new TypeError("Expected a string, got a ".concat(typeof string));
    }

    return string.toLowerCase().replace(/(?:^|\s|-|\/)\S/g, function (m) {
      return m.toUpperCase();
    });
  }
});