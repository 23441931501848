define("ember-composable-helpers/helpers/previous", ["exports", "ember-composable-helpers/utils/get-index", "ember-composable-helpers/-private/create-needle-haystack-helper"], function (_exports, _getIndex, _createNeedleHaystackHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.previous = previous;
  _exports.default = void 0;

  function previous(currentValue, array) {
    let useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let currentIndex = (0, _getIndex.default)(array, currentValue, useDeepEqual);

    if (Ember.isEmpty(currentIndex)) {
      return;
    }

    return currentIndex === 0 ? currentValue : Ember.A(array).objectAt(currentIndex - 1);
  }

  var _default = (0, _createNeedleHaystackHelper.default)(previous);

  _exports.default = _default;
});