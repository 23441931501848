define('ember-keyboard/services/keyboard', ['exports', 'ember-keyboard/listeners/key-events', 'ember-keyboard/utils/handle-key-event'], function (exports, _keyEvents, _handleKeyEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isPropagationEnabled: false,

    registeredResponders: Ember.computed(() => Ember.A()),

    activeResponders: Ember.computed.filterBy('registeredResponders', 'keyboardActivated'),

    sortedRespondersSortDefinition: Ember.computed('isPropagationEnabled', function () {
      return Ember.get(this, 'isPropagationEnabled') ? ['keyboardPriority:desc'] : ['keyboardFirstResponder:desc', 'keyboardPriority:desc'];
    }),

    sortedResponders: Ember.computed.sort('activeResponders', 'sortedRespondersSortDefinition'),

    firstResponders: Ember.computed.filterBy('sortedResponders', 'keyboardFirstResponder'),

    normalResponders: Ember.computed.filter('sortedResponders.@each.keyboardFirstResponder', responder => !Ember.get(responder, 'keyboardFirstResponder')),

    init(...args) {
      this._super(...args);

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      const config = Ember.getOwner(this).resolveRegistration('config:environment') || {};

      const isPropagationEnabled = Boolean(Ember.get(config, 'emberKeyboard.propagation'));
      Ember.set(this, 'isPropagationEnabled', isPropagationEnabled);

      this._boundRespond = Ember.run.bind(this, this._respond);
      this._listeners = Ember.get(config, 'emberKeyboard.listeners') || ['keyUp', 'keyDown', 'keyPress'];
      this._listeners = this._listeners.map(listener => listener.toLowerCase());

      this._listeners.forEach(type => {
        document.addEventListener(type, this._boundRespond);
      });
    },

    willDestroy(...args) {
      this._super(...args);

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      this._listeners.forEach(type => {
        document.removeEventListener(type, this._boundRespond);
      });
    },

    _respond(event) {
      Ember.run(() => {
        if (Ember.get(this, 'isPropagationEnabled')) {
          (0, _handleKeyEvent.handleKeyEventWithPropagation)(event, Ember.getProperties(this, 'firstResponders', 'normalResponders'));
        } else {
          (0, _handleKeyEvent.handleKeyEventWithLaxPriorities)(event, Ember.get(this, 'sortedResponders'));
        }
      });
    },

    register(responder) {
      Ember.get(this, 'registeredResponders').pushObject(responder);
    },

    unregister(responder) {
      Ember.get(this, 'registeredResponders').removeObject(responder);
    },

    keyDown(...args) {
      return (0, _keyEvents.keyDown)(...args);
    },

    keyPress(...args) {
      return (0, _keyEvents.keyPress)(...args);
    },

    keyUp(...args) {
      return (0, _keyEvents.keyUp)(...args);
    }
  });
});