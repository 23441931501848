define("ember-bootstrap/templates/components/bs-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ugy6CMsa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"button\"],[[28,\"component\",[[24,[\"buttonComponent\"]]],[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[24,[\"type\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"buttonPressed\"],null]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs"
    }
  });

  _exports.default = _default;
});