define("ember-content-placeholders/templates/components/content-placeholders-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MzjKab5T",
    "block": "{\"symbols\":[],\"statements\":[[4,\"each\",[[24,[\"linesArray\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"className\"],\"__line\"]]],[10,\"data-test-ember-content-placeholders-text-line\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-content-placeholders/templates/components/content-placeholders-text.hbs"
    }
  });

  _exports.default = _default;
});