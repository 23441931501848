define('ember-rollbar-client/services/rollbar', ['exports', 'rollbar', 'lodash.merge'], function (exports, _rollbar, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    enabled: Ember.computed({
      get() {
        return this.get('config.enabled');
      },

      set(key, value) {
        this.get('notifier').configure({ enabled: value });
        return value;
      }
    }),

    currentUser: Ember.computed({
      get() {},

      set(key, value) {
        this.get('notifier').configure({ payload: { person: value } });
        return value;
      }
    }),

    notifier: Ember.computed(function () {
      return this.rollbarClient();
    }).readOnly(),

    config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment').emberRollbarClient;
    }).readOnly(),

    rollbarClient(customConfig = {}) {
      let config = (0, _lodash.default)({}, this.get('config'), customConfig);
      return new _rollbar.default(config);
    },

    // Notifications

    critical(message, data = {}) {
      return this.get('notifier').critical(message, data);
    },

    error(message, data = {}) {
      return this.get('notifier').error(message, data);
    },

    warning(message, data = {}) {
      return this.get('notifier').warning(message, data);
    },

    info(message, data = {}) {
      return this.get('notifier').info(message, data);
    },

    debug(message, data = {}) {
      return this.get('notifier').debug(message, data);
    }
  });
});