define("ember-data-change-tracker/mixins/keep-only-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // EmberData does not serialize hasMany relationships by default
  var _default = Ember.Mixin.create({
    keepValue(record, key) {
      return record.get('isNew') || record.didChange(key);
    },

    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (this.keepValue(snapshot.record, key)) {
        return this._super(...arguments);
      }
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      if (this.keepValue(snapshot.record, relationship.key)) {
        return this._super(...arguments);
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (this.keepValue(snapshot.record, relationship.key)) {
        return this._super(...arguments);
      }
    }
  });

  _exports.default = _default;
});