define("ember-power-calendar/components/power-calendar/days", ["exports", "ember-power-calendar/templates/components/power-calendar/days", "ember-power-calendar-utils"], function (_exports, _days, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  var _default = Ember.Component.extend({
    layout: _days.default,
    focusedId: null,
    showDaysAround: true,
    classNames: ['ember-power-calendar-days'],
    weekdayFormat: 'short',
    // "min" | "short" | "long"
    powerCalendarService: Ember.inject.service('power-calendar'),
    attributeBindings: ['data-power-calendar-id'],
    // CPs
    'data-power-calendar-id': Ember.computed.oneWay('calendar.uniqueId'),
    weekdaysMin: Ember.computed('calendar.locale', function () {
      return (0, _emberPowerCalendarUtils.withLocale)(this.get("calendar.locale"), _emberPowerCalendarUtils.getWeekdaysMin);
    }),
    weekdaysShort: Ember.computed('calendar.locale', function () {
      return (0, _emberPowerCalendarUtils.withLocale)(this.get("calendar.locale"), _emberPowerCalendarUtils.getWeekdaysShort);
    }),
    weekdays: Ember.computed('calendar.locale', function () {
      return (0, _emberPowerCalendarUtils.withLocale)(this.get("calendar.locale"), _emberPowerCalendarUtils.getWeekdays);
    }),
    localeStartOfWeek: Ember.computed('weekdaysShort', 'startOfWeek', function () {
      let forcedStartOfWeek = this.get('startOfWeek');

      if (forcedStartOfWeek) {
        return parseInt(forcedStartOfWeek, 10);
      }

      return (0, _emberPowerCalendarUtils.localeStartOfWeek)(this.get('calendar.locale'));
    }),
    weekdaysNames: Ember.computed('localeStartOfWeek', 'weekdayFormat', 'calendar.locale', function () {
      let {
        localeStartOfWeek,
        weekdayFormat
      } = this.getProperties('localeStartOfWeek', 'weekdayFormat');
      let format = "weekdays".concat(weekdayFormat === 'long' ? '' : weekdayFormat === 'min' ? 'Min' : 'Short');
      let weekdaysNames = this.get(format);
      return weekdaysNames.slice(localeStartOfWeek).concat(weekdaysNames.slice(0, localeStartOfWeek));
    }),
    days: Ember.computed('calendar', 'focusedId', 'localeStartOfWeek', 'minDate', 'maxDate', 'disabledDates.[]', 'maxLength', function () {
      let today = this.get('powerCalendarService').getDate();
      let calendar = this.get('calendar');
      let lastDay = this.lastDay();
      let day = this.firstDay();
      let days = [];

      while ((0, _emberPowerCalendarUtils.isBefore)(day, lastDay)) {
        days.push(this.buildDay(day, today, calendar));
        day = (0, _emberPowerCalendarUtils.add)(day, 1, "day");
      }

      return days;
    }),
    weeks: Ember.computed('showDaysAround', 'days', function () {
      let {
        showDaysAround,
        days
      } = this.getProperties('showDaysAround', 'days');
      let weeks = [];
      let i = 0;

      while (days[i]) {
        let daysOfWeek = days.slice(i, i + 7);

        if (!showDaysAround) {
          daysOfWeek = daysOfWeek.filter(d => d.isCurrentMonth);
        }

        weeks.push({
          id: "week-of-".concat(daysOfWeek[0].id),
          days: daysOfWeek,
          missingDays: 7 - daysOfWeek.length
        });
        i += 7;
      }

      return weeks;
    }),
    center: null,
    currentCenter: Ember.computed('center', 'calendar.center', function () {
      let center = this.get('center');

      if (!center) {
        center = this.get('selected') || this.get('calendar.center');
      }

      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),

    // Lifecycle hooks
    init() {
      this._super(...arguments);

      this._handleDayClick = this._handleDayClick.bind(this);
    },

    didInsertElement() {
      this._super(...arguments);

      this.element.addEventListener('click', this._handleDayClick);
    },

    willRemoveElement() {
      this._super(...arguments);

      this.element.removeEventListener('click', this._handleDayClick);
    },

    // Actions
    actions: {
      onFocusDay(day) {
        Ember.run.scheduleOnce('actions', this, this._updateFocused, day.id);
      },

      onBlurDay() {
        Ember.run.scheduleOnce('actions', this, this._updateFocused, null);
      },

      onKeyDown(calendar, e) {
        let focusedId = this.get('focusedId');

        if (focusedId) {
          let days = this.get('days');
          let day, index;

          for (let i = 0; i < days.length; i++) {
            if (days[i].id === focusedId) {
              index = i;
              break;
            }
          }

          if (e.keyCode === 38) {
            e.preventDefault();
            let newIndex = Math.max(index - 7, 0);
            day = days[newIndex];

            if (day.isDisabled) {
              for (let i = newIndex + 1; i <= index; i++) {
                day = days[i];

                if (!day.isDisabled) {
                  break;
                }
              }
            }
          } else if (e.keyCode === 40) {
            e.preventDefault();
            let newIndex = Math.min(index + 7, days.length - 1);
            day = days[newIndex];

            if (day.isDisabled) {
              for (let i = newIndex - 1; i >= index; i--) {
                day = days[i];

                if (!day.isDisabled) {
                  break;
                }
              }
            }
          } else if (e.keyCode === 37) {
            day = days[Math.max(index - 1, 0)];

            if (day.isDisabled) {
              return;
            }
          } else if (e.keyCode === 39) {
            day = days[Math.min(index + 1, days.length - 1)];

            if (day.isDisabled) {
              return;
            }
          } else {
            return;
          }

          this.set('focusedId', day.id);
          Ember.run.scheduleOnce('afterRender', this, '_focusDate', day.id);
        }
      }

    },

    // Methods
    buildDay(date, today, calendar) {
      let id = (0, _emberPowerCalendarUtils.formatDate)(date, 'YYYY-MM-DD');
      return (0, _emberPowerCalendarUtils.normalizeCalendarDay)({
        id,
        number: date.getDate(),
        date: new Date(date),
        isDisabled: this.dayIsDisabled(date),
        isFocused: this.get('focusedId') === id,
        isCurrentMonth: date.getMonth() === this.get('currentCenter').getMonth(),
        isToday: (0, _emberPowerCalendarUtils.isSame)(date, today, 'day'),
        isSelected: this.dayIsSelected(date, calendar)
      });
    },

    buildonSelectValue(day) {
      return day;
    },

    dayIsSelected(date) {
      let calendar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('calendar');
      return calendar.selected ? (0, _emberPowerCalendarUtils.isSame)(date, calendar.selected, 'day') : false;
    },

    dayIsDisabled(date) {
      let isDisabled = !this.get('calendar.actions.select');

      if (isDisabled) {
        return true;
      }

      let minDate = this.get('minDate');

      if (minDate && (0, _emberPowerCalendarUtils.isBefore)(date, minDate)) {
        return true;
      }

      let maxDate = this.get('maxDate');

      if (maxDate && (0, _emberPowerCalendarUtils.isAfter)(date, maxDate)) {
        return true;
      }

      let disabledDates = this.get('disabledDates');

      if (disabledDates) {
        let disabledInRange = disabledDates.some(d => {
          let isSameDay = (0, _emberPowerCalendarUtils.isSame)(date, d, 'day');
          let isWeekDayIncludes = WEEK_DAYS.indexOf(d) !== -1 && (0, _emberPowerCalendarUtils.formatDate)(date, 'ddd') === d;
          return isSameDay || isWeekDayIncludes;
        });

        if (disabledInRange) {
          return true;
        }
      }

      return false;
    },

    firstDay() {
      let firstDay = (0, _emberPowerCalendarUtils.startOf)(this.get('currentCenter'), 'month');
      return (0, _emberPowerCalendarUtils.startOfWeek)(firstDay, this.get('localeStartOfWeek'));
    },

    lastDay() {
      let localeStartOfWeek = this.get('localeStartOfWeek');
      (false && !(!isNaN(this.get('currentCenter').getTime())) && Ember.assert("The center of the calendar is an invalid date.", !isNaN(this.get('currentCenter').getTime())));
      let lastDay = (0, _emberPowerCalendarUtils.endOf)(this.get('currentCenter'), 'month');
      return (0, _emberPowerCalendarUtils.endOfWeek)(lastDay, localeStartOfWeek);
    },

    _updateFocused(id) {
      this.set('focusedId', id);
    },

    _focusDate(id) {
      let dayElement = this.element.querySelector("[data-date=\"".concat(id, "\"]"));

      if (dayElement) {
        dayElement.focus();
      }
    },

    _handleDayClick(e) {
      let dayEl = e.target.closest('[data-date]');

      if (dayEl) {
        let dateStr = dayEl.dataset.date;
        let day = this.get('days').find(d => d.id === dateStr);

        if (day) {
          let calendar = this.get('calendar');

          if (calendar.actions.select) {
            calendar.actions.select(day, calendar, e);
          }
        }
      }
    }

  });

  _exports.default = _default;
});