define("ember-bootstrap/templates/components/bs-form/element/layout/vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "meMy8Wvy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasLabel\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[24,[\"labelComponent\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"],[1,[28,\"component\",[[24,[\"errorsComponent\"]]],null],false],[0,\"\\n\"],[1,[28,\"component\",[[24,[\"helpTextComponent\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical.hbs"
    }
  });

  _exports.default = _default;
});