define("ember-leaflet/components/wms-tile-layer", ["exports", "ember-leaflet/components/tile-layer"], function (_exports, _tileLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tileLayer.default.extend({
    leafletOptions: Object.freeze(['layers', 'styles', 'format', 'transparent', 'version', 'crs']),

    createLayer() {
      return this.L.tileLayer.wms(...this.get('requiredOptions'), this.get('options'));
    }

  });

  _exports.default = _default;
});