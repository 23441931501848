define('ember-power-select-blockless/components/power-select-multiple-blockless', ['exports', 'ember-power-select-blockless/templates/components/power-select-multiple-blockless'], function (exports, _powerSelectMultipleBlockless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _powerSelectMultipleBlockless.default,

    // CPs
    searchField: Ember.computed('labelPath', function () {
      return this.get('labelPath');
    })
  });
});