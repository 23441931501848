define('ember-rollbar-client/instance-initializers/rollbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    let fastbootService = appInstance.lookup('service:fastboot');
    let rollbarService = appInstance.lookup('service:rollbar');
    let oldOnError = Ember.onerror || function () {};

    Ember.onerror = (...args) => {
      oldOnError(...args);
      let enabled = rollbarService.get('enabled');

      if (enabled) {
        rollbarService.error(...args);
      }

      if (!enabled || Ember.testing) {
        if (!fastbootService || !fastbootService.get('isFastBoot')) {
          throw args[0];
        }
      }
    };
  }

  exports.default = { initialize };
});