define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A5lu16PU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"fluid\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[28,\"component\",[[24,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[28,\"action\",[[23,0,[]],\"toggleNavbar\"],null],[24,[\"_collapsed\"]]]]],[28,\"component\",[[24,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[24,[\"_collapsed\"]],[24,[\"onCollapsed\"]],[24,[\"onExpanded\"]]]]],[28,\"component\",[[24,[\"navComponent\"]]],[[\"linkToComponent\"],[[28,\"component\",[[24,[\"linkToComponent\"]]],[[\"onCollapse\"],[[28,\"action\",[[23,0,[]],\"collapse\"],null]]]]]]],[28,\"action\",[[23,0,[]],\"collapse\"],null],[28,\"action\",[[23,0,[]],\"expand\"],null],[28,\"action\",[[23,0,[]],\"toggleNavbar\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[28,\"component\",[[24,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[28,\"action\",[[23,0,[]],\"toggleNavbar\"],null],[24,[\"_collapsed\"]]]]],[28,\"component\",[[24,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[24,[\"_collapsed\"]],[24,[\"onCollapsed\"]],[24,[\"onExpanded\"]]]]],[28,\"component\",[[24,[\"navComponent\"]]],[[\"linkToComponent\"],[[28,\"component\",[[24,[\"linkToComponent\"]]],[[\"onCollapse\"],[[28,\"action\",[[23,0,[]],\"collapse\"],null]]]]]]],[28,\"action\",[[23,0,[]],\"collapse\"],null],[28,\"action\",[[23,0,[]],\"expand\"],null],[28,\"action\",[[23,0,[]],\"toggleNavbar\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});