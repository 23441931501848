define("ember-date-components/helpers/is-equal-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqualTime = isEqualTime;
  _exports.default = void 0;

  function isEqualTime(_ref) {
    let [d1, d2] = _ref;

    if (!_moment.default.isMoment(d1) || !_moment.default.isMoment(d2)) {
      return false;
    }

    return d1.format('HH:mm') === d2.format('HH:mm');
  }

  var _default = Ember.Helper.helper(isEqualTime);

  _exports.default = _default;
});