define("ember-power-calendar/components/power-calendar-multiple", ["exports", "ember-power-calendar/components/power-calendar", "ember-power-calendar-utils"], function (_exports, _powerCalendar, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _powerCalendar.default.extend({
    daysComponent: 'power-calendar-multiple/days',
    _calendarType: 'multiple',
    // CPs
    selected: Ember.computed({
      get() {
        return undefined;
      },

      set(_, v) {
        return Ember.isArray(v) ? v.map(_emberPowerCalendarUtils.normalizeDate) : v;
      }

    }),
    currentCenter: Ember.computed('center', function () {
      let center = this.get('center');

      if (!center) {
        center = (this.get('selected') || [])[0] || this.get('powerCalendarService').getDate();
      }

      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),
    // Actions
    actions: {
      select(dayOrDays, calendar, e) {
        (false && !(Ember.isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date) && Ember.assert("The select action expects an array of date objects, or a date object. ".concat(typeof dayOrDays, " was recieved instead."), Ember.isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date));
        let action = this.get("onSelect");
        let days;

        if (Ember.isArray(dayOrDays)) {
          days = dayOrDays;
        } else if (dayOrDays instanceof Object && dayOrDays.date instanceof Date) {
          days = [dayOrDays];
        }

        if (action) {
          action(this._buildCollection(days), calendar, e);
        }
      }

    },

    // Methods
    _buildCollection(days) {
      let selected = this.get("publicAPI.selected") || [];

      for (let day of days) {
        let index = selected.findIndex(selectedDate => (0, _emberPowerCalendarUtils.isSame)(day.date, selectedDate, "day"));

        if (index === -1) {
          selected = [...selected, day.date];
        } else {
          selected = selected.slice(0, index).concat(selected.slice(index + 1));
        }
      }

      return (0, _emberPowerCalendarUtils.normalizeMultipleActionValue)({
        date: selected
      });
    }

  });

  _exports.default = _default;
});