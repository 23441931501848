define("ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+w2z1hfO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-check\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n  \"],[1,[28,\"component\",[[24,[\"labelComponent\"]]],null],false],[0,\"\\n  \"],[1,[28,\"component\",[[24,[\"errorsComponent\"]]],null],false],[0,\"\\n  \"],[1,[28,\"component\",[[24,[\"helpTextComponent\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox.hbs"
    }
  });

  _exports.default = _default;
});