define("ember-date-components/helpers/is-equal-day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqualDay = isEqualDay;
  _exports.default = void 0;

  function isEqualDay(_ref) {
    let [d1, d2] = _ref;

    if (Ember.typeOf(d2) === 'array') {
      return !!Ember.A(d2).find(d2 => {
        if (!_moment.default.isMoment(d1) || !_moment.default.isMoment(d2)) {
          return false;
        }

        return d1.format('YYYY-MM-DD') === d2.format('YYYY-MM-DD');
      });
    }

    if (!_moment.default.isMoment(d1) || !_moment.default.isMoment(d2)) {
      return false;
    }

    return d1.format('YYYY-MM-DD') === d2.format('YYYY-MM-DD');
  }

  var _default = Ember.Helper.helper(isEqualDay);

  _exports.default = _default;
});