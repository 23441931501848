define("ember-bootstrap/templates/components/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vi9awIQN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[28,\"component\",[[24,[\"buttonComponent\"]]],[[\"dropdown\",\"onClick\"],[[23,0,[]],[28,\"action\",[[23,0,[]],\"toggleDropdown\"],null]]]],[28,\"component\",[[24,[\"toggleComponent\"]]],[[\"dropdown\",\"inNav\",\"onClick\"],[[23,0,[]],[24,[\"inNav\"]],[28,\"action\",[[23,0,[]],\"toggleDropdown\"],null]]]],[28,\"component\",[[24,[\"menuComponent\"]]],[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"dropdownElementId\"],[[24,[\"isOpen\"]],[24,[\"direction\"]],[24,[\"inNav\"]],[24,[\"toggleElement\"]],[24,[\"elementId\"]]]]],[28,\"action\",[[23,0,[]],\"toggleDropdown\"],null],[28,\"action\",[[23,0,[]],\"openDropdown\"],null],[28,\"action\",[[23,0,[]],\"closeDropdown\"],null],[24,[\"isOpen\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs"
    }
  });

  _exports.default = _default;
});