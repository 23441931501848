define("ember-power-calendar/templates/components/power-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2jwM7rpo",
    "block": "{\"symbols\":[\"calendar\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"assign\",[[24,[\"publicAPI\"]],[28,\"hash\",null,[[\"nav\",\"days\"],[[28,\"component\",[[24,[\"navComponent\"]]],[[\"calendar\"],[[28,\"readonly\",[[24,[\"publicAPI\"]]],null]]]],[28,\"component\",[[24,[\"daysComponent\"]]],[[\"calendar\"],[[28,\"readonly\",[[24,[\"publicAPI\"]]],null]]]]]]]],null]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[\"nav\"]],false],[0,\"\\n    \"],[1,[23,1,[\"days\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-calendar/templates/components/power-calendar.hbs"
    }
  });

  _exports.default = _default;
});