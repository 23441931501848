define("ember-bootstrap/templates/components/bs-tooltip/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d2N/RoAP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-popper\",null,[[\"id\",\"class\",\"ariaRole\",\"placement\",\"renderInPlace\",\"popperTarget\",\"modifiers\",\"popperContainer\",\"onCreate\",\"onUpdate\"],[[24,[\"id\"]],[24,[\"popperClass\"]],[24,[\"ariaRole\"]],[24,[\"placement\"]],[24,[\"renderInPlace\"]],[24,[\"popperTarget\"]],[24,[\"popperModifiers\"]],\"#ember-bootstrap-wormhole\",[28,\"action\",[[23,0,[]],\"updatePlacement\"],null],[28,\"action\",[[23,0,[]],\"updatePlacement\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[22,\"arrowClass\"]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tooltip-inner\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs"
    }
  });

  _exports.default = _default;
});