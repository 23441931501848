define("ember-leaflet/components/circle-layer", ["exports", "ember-leaflet/components/point-path-layer"], function (_exports, _pointPathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pointPathLayer.default.extend({
    leafletRequiredOptions: Object.freeze(['radius']),
    leafletProperties: Object.freeze(['radius']),

    createLayer() {
      return this.L.circle(...this.get('requiredOptions'), this.get('options'));
    }

  });

  _exports.default = _default;
});