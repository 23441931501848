define("ember-bootstrap/templates/components/bs-progress/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nv3TzMzM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"showLabel\"]]],null,{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[24,[\"percentRounded\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"percentRounded\"],false],[0,\"%\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"sr-only\"],[8],[14,1,[[24,[\"percentRounded\"]]]],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"sr-only\"],[8],[1,[22,\"percentRounded\"],false],[0,\"%\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-progress/bar.hbs"
    }
  });

  _exports.default = _default;
});