define("ember-bootstrap/templates/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jSLdyFfX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"title\",\"close\"],[[28,\"component\",[[24,[\"titleComponent\"]]],null],[28,\"component\",[[24,[\"closeComponent\"]]],[[\"onClick\"],[[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[24,[\"titleComponent\"]]],null,{\"statements\":[[1,[22,\"title\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"closeButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"closeComponent\"]]],[[\"onClick\"],[[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/header.hbs"
    }
  });

  _exports.default = _default;
});