define('ember-keyboard/fixtures/valid-keys', ['exports', 'ember-keyboard/fixtures/code-map', 'ember-keyboard/fixtures/modifiers-array', 'ember-keyboard/fixtures/non-unique-valid-keys'], function (exports, _codeMap, _modifiersArray, _nonUniqueValidKeys) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const keyMapValues = Object.keys(_codeMap.default).map(key => _codeMap.default[key]);

  exports.default = keyMapValues.concat(_modifiersArray.default).concat(_nonUniqueValidKeys.default);
});