define("ember-power-calendar/templates/components/power-calendar/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zg8lRvkO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"nav\",true],[10,\"class\",\"ember-power-calendar-nav\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"calendar\",\"actions\",\"changeCenter\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"ember-power-calendar-nav-control ember-power-calendar-nav-control--previous\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"calendar\",\"actions\",\"moveCenter\"]],-1,[24,[\"unit\"]],[24,[\"calendar\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"«\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ember-power-calendar-nav-title\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[24,[\"calendar\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"power-calendar-format-date\",[[24,[\"calendar\",\"center\"]],[24,[\"format\"]]],[[\"locale\"],[[24,[\"calendar\",\"locale\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"calendar\",\"actions\",\"changeCenter\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"ember-power-calendar-nav-control ember-power-calendar-nav-control--next\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"calendar\",\"actions\",\"moveCenter\"]],1,[24,[\"unit\"]],[24,[\"calendar\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"»\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-calendar/templates/components/power-calendar/nav.hbs"
    }
  });

  _exports.default = _default;
});